// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Leetcode Wizard
 * Leetcode Wizard API Description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface RequestTransferTokenRequestDto
 */
export interface RequestTransferTokenRequestDto {
    /**
     *
     * @type {string}
     * @memberof RequestTransferTokenRequestDto
     */
    redirectUrl: string;
}

/**
 * Check if a given object implements the RequestTransferTokenRequestDto interface.
 */
export function instanceOfRequestTransferTokenRequestDto(
    value: object,
): value is RequestTransferTokenRequestDto {
    if (!('redirectUrl' in value) || value['redirectUrl'] === undefined) return false;
    return true;
}

export function RequestTransferTokenRequestDtoFromJSON(json: any): RequestTransferTokenRequestDto {
    return RequestTransferTokenRequestDtoFromJSONTyped(json, false);
}

export function RequestTransferTokenRequestDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): RequestTransferTokenRequestDto {
    if (json == null) {
        return json;
    }
    return {
        redirectUrl: json['redirectUrl'],
    };
}

export function RequestTransferTokenRequestDtoToJSON(
    value?: RequestTransferTokenRequestDto | null,
): any {
    if (value == null) {
        return value;
    }
    return {
        redirectUrl: value['redirectUrl'],
    };
}
