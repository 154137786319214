import { createApp } from 'vue';
import './assets/index.css';
import App from './App.vue';
import { createPinia } from 'pinia';
import { router } from './router.ts';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { WebSocketService } from './ws/WebSocketService.ts';
import { bootstrapDesktopClient, bootstrapWebView } from './boostrap.ts';
import * as Sentry from '@sentry/vue';
import { setDarkModeClass } from './utils/AppUtil.ts';

// Backwards compatibility
// @TODO: Remove
/* eslint-disable @typescript-eslint/no-explicit-any */
if (window.electronApi === undefined && (window as any).electronAPI !== undefined) {
    window.electronApi = (window as any).electronAPI;
}

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = createApp(App);
app.use(router).use(pinia).mount('#app');

Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration({ router })],
});

setDarkModeClass();

WebSocketService.instance.connect();

if (window.electronApi) {
    bootstrapDesktopClient();
} else {
    bootstrapWebView();
}
