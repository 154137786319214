import { acceptHMRUpdate, defineStore } from 'pinia';

interface State {
    shouldReconnect: boolean;
    showWebSocketStatus: boolean;
    isConnected: boolean;
    connectedClients: { id: string; isDesktopApp: boolean }[];
}

export const useWebSocketStore = defineStore('web-socket', {
    state: (): State => {
        return {
            shouldReconnect: true,
            showWebSocketStatus: true,
            isConnected: false,
            connectedClients: [],
        };
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useWebSocketStore, import.meta.hot));
}
