<script setup lang="ts">
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from '../ui/alert-dialog';
import HotkeyIndicatorInline from '../hotkey/HotkeyIndicatorInline.vue';
import { Hotkey } from '../../types/Hotkey.ts';
import { onMounted, onUnmounted, reactive } from 'vue';
import { eventEmitterUtil } from '../../utils/EventEmitterUtil.ts';
import { hideAppAction } from '../../actions/electron/HideAppAction.ts';

interface ComponentState {
    isOpen: boolean;
    isLoading: boolean;
}

const state: ComponentState = reactive({
    isOpen: false,
    isLoading: false,
});

const openDialog = () => {
    state.isOpen = true;
};

const closeDialog = () => {
    state.isOpen = false;
};

const continueHide = () => {
    state.isLoading = true;
    state.isOpen = false;

    setTimeout(() => {
        hideAppAction();
        state.isLoading = false;
    }, 150);
};

onMounted(() => {
    eventEmitterUtil.addListener('showFirstTimeHideWarning', openDialog);
});

onUnmounted(() => {
    eventEmitterUtil.removeListener('showFirstTimeHideWarning', openDialog);
});
</script>

<template>
    <AlertDialog :open="state.isOpen">
        <AlertDialogContent>
            <AlertDialogHeader>
                <AlertDialogTitle>Hidden mode</AlertDialogTitle>
                <AlertDialogDescription>
                    <p>
                        The app will now enter hidden mode, you can press the hide hotkey
                        <HotkeyIndicatorInline
                            :hotkey="Hotkey.Hide"
                            class="h-[1.25rem] items-center justify-center overflow-hidden rounded border border-muted-foreground px-1 py-0.5 text-xs tracking-wide"
                        />
                        to unhide the appplication once you are done.
                    </p>

                    <p class="mt-4">This warning is only shown once.</p>
                </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
                <AlertDialogCancel @click="closeDialog">Cancel</AlertDialogCancel>
                <AlertDialogAction :disabled="state.isLoading" @click="continueHide"
                    >Hide</AlertDialogAction
                >
            </AlertDialogFooter>
        </AlertDialogContent>
    </AlertDialog>
</template>
