import { useClientStore } from '../../../stores/ClientStore.ts';
import { extractJsonFromString } from 'extract-json-from-string-y';
import { STATIC_NAMESPACE_ID } from '../../../constants/StaticNamespaceId.ts';
import { v5 as uuidv5 } from 'uuid';
import { WebSocketStreamData } from '../../../types/ws/WebSocketStreamData.ts';
import { generateAlgorithmStreamId } from '../../id/GenerateAlgorithmStreamId.ts';

interface ExtractedAlgorithm {
    algorithmName: string;
    shortExplanation: string;
    spaceComplexity: string;
    timeComplexity: string;
}

export const solveRequestAlgorithmsStreamContentHandler = (
    messageData: WebSocketStreamData,
): void => {
    const clientStore = useClientStore();

    const streamId = messageData.streamId;
    const activeSolveId = clientStore.activeSolveId;
    if (!activeSolveId) {
        return;
    }

    // Ignore data from old requests
    const activeAlgorithmStreamId = generateAlgorithmStreamId(activeSolveId);
    if (streamId !== activeAlgorithmStreamId) {
        return;
    }

    const extractedAlgorithms = extractJsonFromString(String(clientStore.streams[streamId]));

    let extractedAlgorithmsFlattened: ExtractedAlgorithm[] = [];
    if (extractedAlgorithms.length > 0) {
        if (Array.isArray(extractedAlgorithms[0])) {
            extractedAlgorithmsFlattened = [...extractedAlgorithms[0]];
        } else {
            extractedAlgorithmsFlattened = extractedAlgorithms;
        }
    }

    if (
        extractedAlgorithmsFlattened.length !== 0 &&
        extractedAlgorithmsFlattened.length <= Object.values(clientStore.solveAlgorithms).length
    ) {
        return;
    }

    for (let i = 0; i < extractedAlgorithmsFlattened.length; i++) {
        const extractedAlgorithm = extractedAlgorithmsFlattened[i];

        const algorithmId = uuidv5(
            `${extractedAlgorithm.algorithmName}_${extractedAlgorithm.shortExplanation}`,
            STATIC_NAMESPACE_ID,
        );

        const outputAlgorithmData = {
            id: algorithmId,
            index: i,
            algorithmName: extractedAlgorithm.algorithmName,
            shortExplanation: extractedAlgorithm.shortExplanation,
            spaceComplexity: extractedAlgorithm.spaceComplexity,
            timeComplexity: extractedAlgorithm.timeComplexity,
        };

        if (clientStore.solveAlgorithms[algorithmId] === undefined) {
            clientStore.solveAlgorithms[algorithmId] = outputAlgorithmData;
        }
    }
};
