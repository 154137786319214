// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Leetcode Wizard
 * Leetcode Wizard API Description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface RequestTransferTokenResponseDto
 */
export interface RequestTransferTokenResponseDto {
    /**
     *
     * @type {string}
     * @memberof RequestTransferTokenResponseDto
     */
    token: string;
}

/**
 * Check if a given object implements the RequestTransferTokenResponseDto interface.
 */
export function instanceOfRequestTransferTokenResponseDto(
    value: object,
): value is RequestTransferTokenResponseDto {
    if (!('token' in value) || value['token'] === undefined) return false;
    return true;
}

export function RequestTransferTokenResponseDtoFromJSON(
    json: any,
): RequestTransferTokenResponseDto {
    return RequestTransferTokenResponseDtoFromJSONTyped(json, false);
}

export function RequestTransferTokenResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): RequestTransferTokenResponseDto {
    if (json == null) {
        return json;
    }
    return {
        token: json['token'],
    };
}

export function RequestTransferTokenResponseDtoToJSON(
    value?: RequestTransferTokenResponseDto | null,
): any {
    if (value == null) {
        return value;
    }
    return {
        token: value['token'],
    };
}
