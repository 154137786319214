import { WebSocketService } from '../../ws/WebSocketService.ts';
import { WS_REQUEST } from '../../ws/WebSocketEvents.ts';
import { STATIC_NAMESPACE_ID } from '../../constants/StaticNamespaceId.ts';
import { Hotkey } from '../../types/Hotkey.ts';
import { useUiStore } from '../../stores/UiStore.ts';

export const syncHotkeyAction = (hotkey: Hotkey): void => {
    const uiStore = useUiStore();
    if (uiStore.isInsideWebView) {
        throw new Error('syncHotkeyAction should not be called from inside the web view');
    }

    WebSocketService.instance.send(WS_REQUEST.sync_hotkey, {
        hotkey: hotkey,
        id: STATIC_NAMESPACE_ID,
    });
};
