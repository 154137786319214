import { useSettingsStore } from '../../stores/SettingsStore.ts';
import { router, ROUTES } from '../../router.ts';
import { useUserInfoStore } from '../../stores/UserInfoStore.ts';
import { SubscriptionTier } from '../../types/user/SubscriptionTier.ts';
import { createTextToast } from '../../utils/ToastUtil.ts';
import { resetAction } from '../app/ResetAction.ts';
import { useHostStore } from '../../stores/HostStore.ts';
import { useClientStore } from '../../stores/ClientStore.ts';
import { v4 as uuidv4 } from 'uuid';
import { eventEmitterUtil } from '../../utils/EventEmitterUtil.ts';
import { WebSocketService } from '../../ws/WebSocketService.ts';
import { apiService } from '../../services/ApiService.ts';
import { ResponseError } from '../../../@generated/api-client';
import { HttpStatusCode } from 'axios';
import { generateAlgorithmStreamId } from '../../ws/id/GenerateAlgorithmStreamId.ts';
import { useUiStore } from '../../stores/UiStore.ts';
import { syncSetSolveAlgorithms } from '../sync/SyncSetSolveAlgorithms.ts';
import { AI_MODEL } from '../../constants/AiModel.ts';
import { generateExtractStreamId } from '../../ws/id/GenerateExtractStreamId.ts';

/**
 * Initiate a solve action
 *
 * @public
 */
export const solveAction = async () => {
    const uiStore = useUiStore();
    if (uiStore.isInsideWebView) {
        console.debug(`solveAction called from inside web view, ignoring...`);
        return;
    }

    const settingsStore = useSettingsStore();
    settingsStore.isOnboarding = false;

    await router.push({ name: ROUTES.index });

    // Check credits
    const userInfoStore = useUserInfoStore();
    if (userInfoStore.subscriptionTier === SubscriptionTier.Trial) {
        if (userInfoStore.creditsRemaining <= 0) {
            createTextToast(
                'Insufficient trial credits',
                'Please upgrade to continue using Leetcode Wizard.',
                'destructive',
                true,
            );

            return;
        }
    }

    await prepareAlgorithmsRequestAction();
};

/**
 * Initiate a solve action: request an input source capture from Electron
 *
 * @private
 */
const prepareAlgorithmsRequestAction = async () => {
    await resetAction(false);

    const hostStore = useHostStore();

    if (!hostStore.selectedSourceId) {
        createTextToast(
            'Unable to solve, failed to capture input source.',
            'Please select a valid input source.',
            'destructive',
            true,
        );

        return false;
    }

    const clientStore = useClientStore();
    const solveId = uuidv4();
    clientStore.availableSolveIds = [...clientStore.availableSolveIds, solveId];
    clientStore.activeSolveId = solveId;

    window.electronApi.requestScreenshot(hostStore.selectedSourceId, clientStore.activeSolveId);

    return true;
};

/**
 * Handle and store the input capture response from Electron
 *
 * @public
 */
export const handleInputSourceCaptureResponseAction = async (
    imageData: string,
    solveId: string,
) => {
    const uiStore = useUiStore();
    if (uiStore.isInsideWebView) {
        console.debug(`solveAction called from inside web view, ignoring...`);
        return;
    }

    if (imageData === 'data:image/png;base64,') {
        createTextToast(
            'Invalid input',
            'Please select a different input source (Options -> Input source).',
            'destructive',
            true,
        );

        return;
    }

    const hostStore = useHostStore();
    hostStore.activeImageData = imageData;

    // Check if source is LeetCode.com when on trial
    const userInfoStore = useUserInfoStore();
    if (
        userInfoStore.subscriptionTier === SubscriptionTier.Trial &&
        (!hostStore.selectedSourceName ||
            !hostStore.selectedSourceName.toLowerCase().includes('leetcode'))
    ) {
        eventEmitterUtil.emit(
            'showTrialInputWarning',
            hostStore.selectedSourceName,
            imageData,
            solveId,
        );

        return;
    }

    eventEmitterUtil.emit('showSolvePreview', imageData);

    const settingsStore = useSettingsStore();

    if (settingsStore.activeAiModel === AI_MODEL['o1-mini']) {
        await makeExtractRequestAction(imageData, solveId);
    } else {
        await makeAlgorithmsRequestAction(imageData, '', '', solveId);
    }
};

/**
 * Make the API request once all data is available
 *
 * @public
 */
export const makeExtractRequestAction = async (imageData: string, solveId: string) => {
    const uiStore = useUiStore();
    if (uiStore.isInsideWebView) {
        console.debug(`solveAction called from inside web view, ignoring...`);
        return;
    }

    const algorithmStreamId = generateAlgorithmStreamId(solveId);
    const extractStreamId = generateExtractStreamId(solveId);

    WebSocketService.instance.verifyConnection();

    const clientStore = useClientStore();
    const settingsStore = useSettingsStore();

    clientStore.solveAlgorithms = {};
    syncSetSolveAlgorithms({});

    clientStore.streamsWaiting = [
        ...clientStore.streamsWaiting,
        extractStreamId,
        algorithmStreamId,
    ];
    clientStore.solveProgrammingLanguage[solveId] = settingsStore.programmingLanguage;

    await router.push({
        name: ROUTES.solveAlgorithmSelection,
        params: {
            solveId: solveId,
        },
    });

    setTimeout(
        () => {
            if (
                clientStore.streams[extractStreamId] === undefined ||
                clientStore.streams[extractStreamId].length <= 0
            ) {
                clientStore.streamsErrored = [
                    ...clientStore.streamsErrored,
                    extractStreamId,
                    algorithmStreamId,
                ];
            }
        },
        settingsStore.activeAiModel === AI_MODEL['o1-mini'] ? 60_000 : 40_000,
    );

    try {
        await apiService.getApi().solveExtractV1({
            solveExtractRequestDto: {
                imageData: imageData,
                solveId: solveId,
                streamId: extractStreamId,
                aiModel: settingsStore.activeAiModel ?? AI_MODEL['gpt-4o'],
            },
        });
    } catch (error) {
        if (
            error instanceof ResponseError &&
            error.response.status === HttpStatusCode.TooManyRequests
        ) {
            createTextToast(
                'Rate limited',
                (await error.response.json()).message,
                'destructive',
                true,
            );
        } else {
            createTextToast(
                'Failed to submit your input',
                'Make sure the selected input source is fully visible',
                'destructive',
                true,
            );
        }

        clientStore.streamsErrored = [...clientStore.streamsErrored, extractStreamId];
        clientStore.streamsWaiting = clientStore.streamsWaiting.filter(
            (id: string) => id !== extractStreamId,
        );

        await router.push({
            name: ROUTES.index,
        });

        throw error;
    }

    return true;
};

/**
 * Make the API request once all data is available
 *
 * @public
 */
export const makeAlgorithmsRequestAction = async (
    imageData: string,
    problemExtract: string,
    exampleCodeExtract: string,
    solveId: string,
) => {
    const uiStore = useUiStore();
    if (uiStore.isInsideWebView) {
        console.debug(`solveAction called from inside web view, ignoring...`);
        return;
    }

    const algorithmStreamId = generateAlgorithmStreamId(solveId);

    WebSocketService.instance.verifyConnection();

    const clientStore = useClientStore();
    const settingsStore = useSettingsStore();

    clientStore.solveAlgorithms = {};
    syncSetSolveAlgorithms({});

    clientStore.streamsWaiting = [...clientStore.streamsWaiting, algorithmStreamId];
    clientStore.solveProgrammingLanguage[solveId] = settingsStore.programmingLanguage;

    await router.push({
        name: ROUTES.solveAlgorithmSelection,
        params: {
            solveId: solveId,
        },
    });

    setTimeout(
        () => {
            if (
                clientStore.streams[algorithmStreamId] === undefined ||
                clientStore.streams[algorithmStreamId].length <= 0
            ) {
                clientStore.streamsErrored = [...clientStore.streamsErrored, algorithmStreamId];
            }
        },
        settingsStore.activeAiModel === AI_MODEL['o1-mini'] ? 60_000 : 40_000,
    );

    try {
        await apiService.getApi().solveAlgorithmsV1({
            solveAlgorithmsRequestDto: {
                imageData: imageData,
                problemExtract: problemExtract,
                exampleCodeExtract: exampleCodeExtract,
                solveId: solveId,
                streamId: algorithmStreamId,
                aiModel: settingsStore.activeAiModel ?? AI_MODEL['gpt-4o'],
            },
        });
    } catch (error) {
        if (
            error instanceof ResponseError &&
            error.response.status === HttpStatusCode.TooManyRequests
        ) {
            createTextToast(
                'Rate limited',
                (await error.response.json()).message,
                'destructive',
                true,
            );
        } else {
            createTextToast(
                'Failed to submit your input',
                'Make sure the selected input source is fully visible',
                'destructive',
                true,
            );
        }

        clientStore.streamsErrored = [...clientStore.streamsErrored, algorithmStreamId];
        clientStore.streamsWaiting = clientStore.streamsWaiting.filter(
            (id: string) => id !== algorithmStreamId,
        );

        await router.push({
            name: ROUTES.index,
        });

        throw error;
    }

    return true;
};
