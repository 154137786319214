import { acceptHMRUpdate, defineStore } from 'pinia';
import { SubscriptionTier } from '../types/user/SubscriptionTier.ts';

interface State {
    userInfoLoading: boolean;
    username: string;
    creditsRemaining: number;
    subscriptionTier: SubscriptionTier;
}

const useUserInfoStore = defineStore('user-info', {
    state: (): State => {
        return {
            userInfoLoading: true,
            username: '',
            creditsRemaining: 10,
            subscriptionTier: SubscriptionTier.Trial,
        };
    },
    persist: {
        storage: sessionStorage,
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useUserInfoStore, import.meta.hot));
}

export { useUserInfoStore };
