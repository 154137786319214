import type { ToastRootProps } from 'radix-vue';
import type { HTMLAttributes } from 'vue';

export { default as Toaster } from './Toaster.vue';
export { default as Toast } from './Toast.vue';
export { default as ToastViewport } from './ToastViewport.vue';
export { default as ToastClose } from './ToastClose.vue';
export { default as ToastTitle } from './ToastTitle.vue';
export { default as ToastDescription } from './ToastDescription.vue';
export { default as ToastProvider } from './ToastProvider.vue';
export { toast, useToast } from './use-toast';

import { type VariantProps, cva } from 'class-variance-authority';

export const toastVariants = cva(
    'group pointer-events-auto relative flex w-full items-center justify-between space-x-2 overflow-hidden rounded-md border p-4 pr-6 shadow-lg transition-all data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)] data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=move]:transition-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out data-[state=closed]:fade-out-80 data-[state=closed]:slide-out-to-right-full data-[state=open]:slide-in-from-top-full data-[state=open]:sm:slide-in-from-bottom-full',
    {
        variants: {
            variant: {
                default: 'border bg-background text-foreground',
                destructive:
                    'destructive group border-destructive bg-destructive text-destructive-foreground',
                success: 'group border-green-800 bg-green-800 text-white',
            },
        },
        defaultVariants: {
            variant: 'default',
        },
    },
);

export type ToastVariants = VariantProps<typeof toastVariants>;

export interface ToastProps extends ToastRootProps {
    class?: HTMLAttributes['class'];
    variant?: ToastVariants['variant'];
    onOpenChange?: ((value: boolean) => void) | undefined;
}
