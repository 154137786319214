enum ProgrammingLanguage {
    'C++' = 'C++',
    'Java' = 'Java',
    'Python' = 'Python',
    'Python3' = 'Python3',
    'MySQL' = 'MySQL',
    'MS SQL Server' = 'MS SQL Server',
    'Oracle SQL' = 'Oracle SQL',
    'C' = 'C',
    'C#' = 'C#',
    'JavaScript' = 'JavaScript',
    'Ruby' = 'Ruby',
    'Bash' = 'Bash',
    'Swift' = 'Swift',
    'Go' = 'Go',
    'Scala' = 'Scala',
    'Kotlin' = 'Kotlin',
    'Rust' = 'Rust',
    'PHP' = 'PHP',
    'TypeScript' = 'TypeScript',
    'Racket' = 'Racket',
    'Erlang' = 'Erlang',
    'Elixir' = 'Elixir',
    'Dart' = 'Dart',
    'PostgreSQL' = 'PostgreSQL',
    'Pandas' = 'Pandas',
    'React' = 'React',
    'Vanilla JS' = 'Vanilla JS',
}

export { ProgrammingLanguage };
