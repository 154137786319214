export class AuthenticationService {
    private static SESSION_STORAGE_TOKEN_KEY = 'token-data';

    private static ACCESS_TOKEN_KEY = 'accessToken';
    private static REFRESH_TOKEN_KEY = 'refreshToken';
    private static FINGERPRINT_HASH_KEY = 'fingerprintHash';
    private static EXPIRES_AT_KEY = 'expiresAt';

    static #instance: AuthenticationService;

    private constructor() {}

    public static get instance(): AuthenticationService {
        if (!AuthenticationService.#instance) {
            AuthenticationService.#instance = new AuthenticationService();
        }

        return AuthenticationService.#instance;
    }

    public storeTokenData(
        accessToken: string,
        refreshToken: string,
        fingerprintHash: string,
        expiresInSeconds: number,
    ): void {
        this.setAccessToken(accessToken);
        this.setRefreshToken(refreshToken);
        this.setFingerprintHash(fingerprintHash);
        this.setExpiresAt(Math.floor(Date.now() / 1000) + expiresInSeconds);
    }

    public clearTokenData(): void {
        sessionStorage.removeItem(AuthenticationService.SESSION_STORAGE_TOKEN_KEY);
    }

    public isLoggedIn(): boolean {
        return this.getAccessToken() !== undefined;
    }

    public getAccessToken(): string | undefined {
        return this.getSessionTokenData(AuthenticationService.ACCESS_TOKEN_KEY);
    }

    private setAccessToken(accessToken: string): void {
        this.setSessionTokenData(AuthenticationService.ACCESS_TOKEN_KEY, accessToken);
    }

    public getRefreshToken(): string | undefined {
        return this.getSessionTokenData(AuthenticationService.REFRESH_TOKEN_KEY);
    }

    private setRefreshToken(refreshToken: string): void {
        this.setSessionTokenData(AuthenticationService.REFRESH_TOKEN_KEY, refreshToken);
    }

    public getFingerprintHash(): string | undefined {
        return this.getSessionTokenData(AuthenticationService.FINGERPRINT_HASH_KEY);
    }

    private setFingerprintHash(fingerprintHash: string): void {
        this.setSessionTokenData(AuthenticationService.FINGERPRINT_HASH_KEY, fingerprintHash);
    }

    // @ts-expect-error Unused at the moment, might use later
    private getExpiresAt(): number | undefined {
        const expiresAt = this.getSessionTokenData(AuthenticationService.EXPIRES_AT_KEY);
        if (!expiresAt) {
            return undefined;
        }

        return Number(expiresAt);
    }

    private setExpiresAt(expiresAt: number): void {
        this.setSessionTokenData(AuthenticationService.EXPIRES_AT_KEY, String(expiresAt));
    }

    private setSessionTokenData(key: string, data: string): void {
        const tokenData = sessionStorage.getItem(AuthenticationService.SESSION_STORAGE_TOKEN_KEY);

        let parsedTokenData;
        if (tokenData) {
            parsedTokenData = JSON.parse(tokenData);
        } else {
            parsedTokenData = {};
        }

        parsedTokenData[key] = data;

        sessionStorage.setItem(
            AuthenticationService.SESSION_STORAGE_TOKEN_KEY,
            JSON.stringify(parsedTokenData),
        );
    }

    private getSessionTokenData(key: string): string | undefined {
        const tokenData = sessionStorage.getItem(AuthenticationService.SESSION_STORAGE_TOKEN_KEY);
        if (!tokenData) {
            return undefined;
        }

        const parsedTokenData = JSON.parse(tokenData);

        return parsedTokenData[key];
    }
}
