import { ProgrammingLanguage } from '../types/ProgrammingLanguage.ts';

const PROGRAMMING_LANGUAGE_HIGHLIGHT_JS: { [key in ProgrammingLanguage]: string } = {
    'C++': 'cpp',
    Java: 'java',
    Python: 'python',
    Python3: 'python',
    MySQL: 'sql',
    'MS SQL Server': 'sql',
    'Oracle SQL': 'sql',
    C: 'c',
    'C#': 'csharp',
    JavaScript: 'javascript',
    Ruby: 'ruby',
    Bash: 'bash',
    Swift: 'swift',
    Go: 'go',
    Scala: 'scala',
    Kotlin: 'kotlin',
    Rust: 'rust',
    PHP: 'php',
    TypeScript: 'typescript',
    Racket: 'lisp',
    Erlang: 'erlang',
    Elixir: 'elixir',
    Dart: 'dart',
    PostgreSQL: 'pgsql',
    Pandas: 'python',
    React: 'javascript',
    'Vanilla JS': 'javascript',
};

export { PROGRAMMING_LANGUAGE_HIGHLIGHT_JS };
