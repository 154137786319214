<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue';
import {
    MenubarRoot,
    type MenubarRootEmits,
    type MenubarRootProps,
    useForwardPropsEmits,
} from 'radix-vue';
import { cn } from '../../../utils/AppUtil.ts';

const props = defineProps<MenubarRootProps & { class?: HTMLAttributes['class'] }>();
const emits = defineEmits<MenubarRootEmits>();

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props;

    return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>

<template>
    <MenubarRoot
        v-bind="forwarded"
        :class="
            cn(
                'flex h-9 items-center space-x-1 rounded-md border bg-background p-1 shadow-sm',
                props.class,
            )
        "
    >
        <slot />
    </MenubarRoot>
</template>
