import { AuthenticationService } from '../../services/AuthenticationService.ts';
import { eventEmitterUtil } from '../../utils/EventEmitterUtil.ts';
import { resetAction } from '../app/ResetAction.ts';
import { router, ROUTES } from '../../router.ts';
import { useAuthStore } from '../../stores/AuthStore.ts';

export const logOutAction = async () => {
    await resetAction(false);

    AuthenticationService.instance.clearTokenData();

    const authStore = useAuthStore();
    authStore.isLoggedIn = false;

    eventEmitterUtil.emit('logout');

    await router.push({ name: ROUTES.authLogin });
};
