import { WebSocketMessageData } from '../../../types/ws/WebSocketMessageData.ts';
import { ClientStoreSyncData } from '../../../types/sync/ClientStoreSyncData.ts';
import { useClientStore } from '../../../stores/ClientStore.ts';
import { useUiStore } from '../../../stores/UiStore.ts';

export const syncSetClientStoreMessageHandler = (messageData: WebSocketMessageData) => {
    const uiStore = useUiStore();
    if (!uiStore.isInsideWebView) {
        return;
    }

    const storeData: ClientStoreSyncData | undefined = messageData?.storeData;
    if (storeData === undefined) {
        return;
    }

    const clientStore = useClientStore();
    clientStore.$patch(storeData);
};
