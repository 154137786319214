import { ToastVariants, useToast } from '../components/ui/toast';
import type { ToasterToast } from '../components/ui/toast/use-toast';
import { syncPushToastAction } from '../actions/sync/SyncPushToastAction.ts';

export const createTextToast = (
    title: string,
    description: string | undefined,
    variant: ToastVariants['variant'],
    pushToWebView: boolean,
) => {
    const { toast } = useToast();

    const props: Omit<ToasterToast, 'id'> = {
        title: title,
        description: description,
        variant: variant,
    };

    toast(props);

    if (pushToWebView) {
        syncPushToastAction(title, description, variant);
    }
};
