<script setup lang="ts">
import { useHostStore } from '../../stores/HostStore.ts';
import { useUserInfoStore } from '../../stores/UserInfoStore.ts';
import { useSettingsStore } from '../../stores/SettingsStore.ts';
import { useUiStore } from '../../stores/UiStore.ts';
import { ref } from 'vue';
import {
    Menubar,
    MenubarContent,
    MenubarMenu,
    MenubarShortcut,
    MenubarTrigger,
} from '../ui/menubar';
import HotkeyIndicator from '../hotkey/HotkeyIndicator.vue';
import { Hotkey } from '../../types/Hotkey.ts';
import { Bot, ChevronDown } from 'lucide-vue-next';
import HotkeyIndicatorInline from '../hotkey/HotkeyIndicatorInline.vue';
import SourceSelect from './InputSourceSelect.vue';
import LanguageSelect from './LanguageSelect.vue';
import OpacitySlider from './OpacitySlider.vue';
import { openWebViewAction } from '../../actions/app/OpenWebViewAction.ts';
import { SubscriptionTier } from '../../types/user/SubscriptionTier.ts';
import { MenubarItem, MenubarSeparator } from '../ui/menubar';
import { Button } from '../ui/button';
import { logOutAction } from '../../actions/auth/LogOutAction.ts';
import { hideAppAction } from '../../actions/electron/HideAppAction.ts';
import { resetAction } from '../../actions/app/ResetAction.ts';
import { openSubscriptionManagementAction } from '../../actions/app/OpenSubscriptionManagementAction.ts';
import { startSubscriptionProcessAction } from '../../actions/app/StartSubscriptionProcessAction.ts';
import { solveAction } from '../../actions/solve/SolveAction.ts';
import { eventEmitterUtil } from '../../utils/EventEmitterUtil.ts';
import { toggleContentProtectionAction } from '../../actions/electron/ToggleContentProtectionAction.ts';
import { getOs } from '../../utils/AppUtil.ts';
import { ToggleTaskbarIconVisibilityAction } from '../../actions/electron/ToggleTaskbarIconVisibilityAction.ts';

const hostStore = useHostStore();
const userInfoStore = useUserInfoStore();
const settingsStore = useSettingsStore();
const uiStore = useUiStore();

const solveDisabled = ref(false);

const solve = async () => {
    solveDisabled.value = true;

    setTimeout(() => {
        solveDisabled.value = false;
    }, 1500);

    await solveAction();
};

const reset = async () => {
    await resetAction(true);
};

const hideApp = async () => {
    await hideAppAction();
};

const logOut = async () => {
    await logOutAction();
};

const openSubscriptionManagement = async () => {
    await openSubscriptionManagementAction();
};

const onSubscriptionButton = async () => {
    await startSubscriptionProcessAction();
};

const getUsernameTruncated = () => {
    const username = userInfoStore.username;

    if (username.length > 12) {
        return username.substring(0, 12) + '...';
    } else {
        return username;
    }
};

const openShortcutConfigurationModal = () => {
    eventEmitterUtil.emit('openShortcutConfigurationDialog');
};

const toggleContentProtection = () => {
    toggleContentProtectionAction(true);
};

const toggleTaskbarIconVisibility = () => {
    ToggleTaskbarIconVisibilityAction();
};

const getTaskbarName = () => {
    if (getOs() === 'windows') {
        return 'taskbar';
    }

    return 'dock';
};

// @TODO: Remove
const showToggleTaskbarIconVisibilityItem = () => {
    return window.electronApi.setTaskbarIconVisibility !== undefined;
};

const openModelSelectionDialog = () => {
    eventEmitterUtil.emit('openModelSelectionDialog');
};
</script>

<template>
    <Menubar
        class="select-none justify-between gap-0 whitespace-nowrap rounded-none border-b border-t-0 p-0 dark:border-gray-800 dark:bg-gray-900"
    >
        <div class="inline-flex">
            <div
                class="ms-2.5 flex items-center justify-center font-semibold"
                v-if="uiStore.isInsideWebView"
            >
                <img src="/icon.png" alt="Icon" class="h-4 w-4" />
                <h1 class="ms-1.5">Leetcode Wizard</h1>
            </div>

            <MenubarMenu>
                <Button
                    v-if="!uiStore.isInsideWebView"
                    class="h-[34px] select-none rounded-none bg-green-700 px-3 py-1.5 text-gray-200 hover:bg-green-800"
                    :size="'xs'"
                    :disabled="
                        !hostStore.selectedSourceId || solveDisabled || settingsStore.isOnboarding
                    "
                    @click="solve"
                >
                    Solve
                    <HotkeyIndicator
                        :hotkey="Hotkey.Solve"
                        class="ms-2 mt-[1px] border-gray-100 text-gray-100"
                    />
                </Button>
            </MenubarMenu>

            <MenubarMenu v-if="!settingsStore.isOnboarding && !uiStore.isInsideWebView">
                <MenubarTrigger class="ml-0 h-[34px] rounded-none">
                    Options
                    <ChevronDown class="ms-2" />
                </MenubarTrigger>
                <MenubarContent>
                    <MenubarItem @click="reset">
                        Reset
                        <MenubarShortcut>
                            <HotkeyIndicatorInline :hotkey="Hotkey.Reset" />
                        </MenubarShortcut>
                    </MenubarItem>
                    <MenubarSeparator />

                    <SourceSelect />
                    <LanguageSelect />
                    <OpacitySlider />

                    <MenubarSeparator />
                    <MenubarItem @click="openModelSelectionDialog">
                        Configure AI model
                        <Bot class="ms-2 h-4 w-5" />
                    </MenubarItem>

                    <MenubarSeparator />

                    <MenubarItem @click="openShortcutConfigurationModal">
                        Configure hotkeys
                    </MenubarItem>
                    <MenubarItem @click="toggleContentProtection">
                        <span v-if="uiStore.isContentProtectionEnabled"
                            >Disable content protection</span
                        >
                        <span v-else>Enable content protection</span>
                    </MenubarItem>
                    <MenubarItem
                        @click="toggleTaskbarIconVisibility"
                        v-if="showToggleTaskbarIconVisibilityItem()"
                    >
                        <span v-if="uiStore.isTaskbarIconVisible"
                            >Hide {{ getTaskbarName() }} icon</span
                        >
                        <span v-else>Show {{ getTaskbarName() }} icon</span>
                    </MenubarItem>

                    <MenubarSeparator />

                    <MenubarItem @click="hideApp">
                        Hide app
                        <MenubarShortcut>
                            <HotkeyIndicatorInline :hotkey="Hotkey.Hide" />
                        </MenubarShortcut>
                    </MenubarItem>
                </MenubarContent>
            </MenubarMenu>
        </div>

        <div class="inline-flex">
            <MenubarMenu>
                <MenubarTrigger class="h-[34px] rounded-none">
                    {{ getUsernameTruncated() }}
                    <ChevronDown class="ms-2" />
                </MenubarTrigger>
                <MenubarContent>
                    <MenubarItem
                        @click="openSubscriptionManagement"
                        v-if="!uiStore.isInsideWebView"
                    >
                        Manage subscription
                    </MenubarItem>
                    <MenubarSeparator v-if="!uiStore.isInsideWebView" />
                    <MenubarItem @click="logOut"> Log out </MenubarItem>
                </MenubarContent>
            </MenubarMenu>
            <Button
                v-if="!settingsStore.isOnboarding && !uiStore.isInsideWebView"
                class="text h-[34px] select-none rounded-none bg-gray-200 px-3 py-1.5 text-primary hover:bg-gray-200/60 dark:bg-gray-700 dark:hover:bg-gray-700/60"
                :size="'xs'"
                @click="openWebViewAction()"
            >
                Web view
            </Button>
            <Button
                v-if="
                    userInfoStore.subscriptionTier !== SubscriptionTier.Pro &&
                    !settingsStore.isOnboarding
                "
                class="h-[34px] select-none rounded-none bg-green-700 px-3 py-1.5 text-gray-200 hover:bg-green-800"
                :size="'xs'"
                @click="onSubscriptionButton"
            >
                Subscribe
            </Button>
        </div>
    </Menubar>
</template>
