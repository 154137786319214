import { AuthenticationService } from './AuthenticationService.ts';
import { BaseApi, Configuration, Middleware, ResponseContext } from '../../@generated/api-client';
import { createTextToast } from '../utils/ToastUtil.ts';
import { logOutAction } from '../actions/auth/LogOutAction.ts';

class AuthMiddleware implements Middleware {
    async post(context: ResponseContext): Promise<Response | void> {
        if (context.response.status === 401 || context.response.status === 403) {
            createTextToast('Session expired', 'Please log in again', 'destructive', true);

            void logOutAction();
        }
    }
}

export const apiService = {
    getApi(): BaseApi {
        const apiConfig = new Configuration({
            basePath: import.meta.env.VITE_API_BASE_URL,
            accessToken: AuthenticationService.instance.getAccessToken() as string, // @TODO
            credentials: 'include',
            middleware: [new AuthMiddleware()],
        });

        return new BaseApi(apiConfig);
    },
};
