import { WebSocketMessageData } from '../../../types/ws/WebSocketMessageData.ts';
import { BLOCKED_SYNC_ROUTES, router } from '../../../router.ts';
import { useUiStore } from '../../../stores/UiStore.ts';

export const syncSetRouteMessageHandler = (messageData: WebSocketMessageData) => {
    const uiStore = useUiStore();
    if (!uiStore.isInsideWebView) {
        return;
    }

    const route = messageData.route;
    if (route === undefined) {
        return;
    }

    for (const blockedRoute of BLOCKED_SYNC_ROUTES) {
        if (route.includes(blockedRoute)) {
            return;
        }
    }

    void router.push(route);
};
