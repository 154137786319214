import { AuthenticationService } from '../../services/AuthenticationService.ts';
import { useUserInfoStore } from '../../stores/UserInfoStore.ts';
import { useSettingsStore } from '../../stores/SettingsStore.ts';
import { wsAuthLogIn } from '../../ws/requests/WsAuthLogIn.ts';
import { SubscriptionTier } from '../../types/user/SubscriptionTier.ts';
import { apiService } from '../../services/ApiService.ts';
import { createTextToast } from '../../utils/ToastUtil.ts';
import { useAuthStore } from '../../stores/AuthStore.ts';
import { logOutAction } from './LogOutAction.ts';
import { useUiStore } from '../../stores/UiStore.ts';

const MAX_RETRIES = 3;

/**
 * @throws {Error} On API error response
 */
export const refreshTokenAction = async (retryAttempt: number = 0) => {
    const authStore = useAuthStore();
    if (!authStore.isLoggedIn) {
        return;
    }

    const curRefreshToken = AuthenticationService.instance.getRefreshToken();
    const curFingerprintHash = AuthenticationService.instance.getFingerprintHash();

    if (!curRefreshToken || !curFingerprintHash) {
        createTextToast('Session expired', 'Please log in again', 'destructive', true);

        void logOutAction();

        return;
    }

    let apiResponse;
    try {
        apiResponse = await apiService.getApi().authRefreshTokenV1({
            refreshTokenRequestDto: {
                refreshToken: curRefreshToken,
                fingerprintHash: curFingerprintHash,
            },
        });
    } catch {
        if (retryAttempt > MAX_RETRIES) {
            const uiStore = useUiStore();

            createTextToast(
                'Failed to refresh session',
                'Please log in again',
                'destructive',
                !uiStore.isInsideWebView,
            );

            void logOutAction();
        } else {
            void refreshTokenAction(retryAttempt + 1);
        }

        return;
    }

    const { refreshToken, accessTokenExpiresIn, fingerprintHash, accessToken } =
        apiResponse.tokenData;

    AuthenticationService.instance.storeTokenData(
        accessToken,
        refreshToken,
        fingerprintHash,
        accessTokenExpiresIn,
    );

    authStore.isLoggedIn = true;

    const { creditsRemaining, username: userUsername, subscriptionTier } = apiResponse.userData;

    const userInfoStore = useUserInfoStore();
    userInfoStore.username = userUsername;
    userInfoStore.creditsRemaining = creditsRemaining;
    userInfoStore.subscriptionTier = subscriptionTier as SubscriptionTier;

    const settingsStore = useSettingsStore();
    settingsStore.rememberedUsername = userUsername;

    const uiStore = useUiStore();
    wsAuthLogIn(accessToken, !uiStore.isInsideWebView);
};
