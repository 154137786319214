// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Leetcode Wizard
 * Leetcode Wizard API Description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UserInfoResponseDto
 */
export interface UserInfoResponseDto {
    /**
     *
     * @type {string}
     * @memberof UserInfoResponseDto
     */
    username: string;
    /**
     *
     * @type {number}
     * @memberof UserInfoResponseDto
     */
    creditsRemaining: number;
    /**
     *
     * @type {string}
     * @memberof UserInfoResponseDto
     */
    subscriptionTier: UserInfoResponseDtoSubscriptionTierEnum;
}

/**
 * @export
 */
export const UserInfoResponseDtoSubscriptionTierEnum = {
    Trial: 'TRIAL',
    Pro: 'PRO',
} as const;
export type UserInfoResponseDtoSubscriptionTierEnum =
    (typeof UserInfoResponseDtoSubscriptionTierEnum)[keyof typeof UserInfoResponseDtoSubscriptionTierEnum];

/**
 * Check if a given object implements the UserInfoResponseDto interface.
 */
export function instanceOfUserInfoResponseDto(value: object): value is UserInfoResponseDto {
    if (!('username' in value) || value['username'] === undefined) return false;
    if (!('creditsRemaining' in value) || value['creditsRemaining'] === undefined) return false;
    if (!('subscriptionTier' in value) || value['subscriptionTier'] === undefined) return false;
    return true;
}

export function UserInfoResponseDtoFromJSON(json: any): UserInfoResponseDto {
    return UserInfoResponseDtoFromJSONTyped(json, false);
}

export function UserInfoResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): UserInfoResponseDto {
    if (json == null) {
        return json;
    }
    return {
        username: json['username'],
        creditsRemaining: json['creditsRemaining'],
        subscriptionTier: json['subscriptionTier'],
    };
}

export function UserInfoResponseDtoToJSON(value?: UserInfoResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        username: value['username'],
        creditsRemaining: value['creditsRemaining'],
        subscriptionTier: value['subscriptionTier'],
    };
}
