// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Leetcode Wizard
 * Leetcode Wizard API Description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface RegisterRequestDto
 */
export interface RegisterRequestDto {
    /**
     *
     * @type {string}
     * @memberof RegisterRequestDto
     */
    username: string;
    /**
     *
     * @type {string}
     * @memberof RegisterRequestDto
     */
    password: string;
    /**
     *
     * @type {string}
     * @memberof RegisterRequestDto
     */
    referralSource?: string;
}

/**
 * Check if a given object implements the RegisterRequestDto interface.
 */
export function instanceOfRegisterRequestDto(value: object): value is RegisterRequestDto {
    if (!('username' in value) || value['username'] === undefined) return false;
    if (!('password' in value) || value['password'] === undefined) return false;
    return true;
}

export function RegisterRequestDtoFromJSON(json: any): RegisterRequestDto {
    return RegisterRequestDtoFromJSONTyped(json, false);
}

export function RegisterRequestDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): RegisterRequestDto {
    if (json == null) {
        return json;
    }
    return {
        username: json['username'],
        password: json['password'],
        referralSource: json['referralSource'] == null ? undefined : json['referralSource'],
    };
}

export function RegisterRequestDtoToJSON(value?: RegisterRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        username: value['username'],
        password: value['password'],
        referralSource: value['referralSource'],
    };
}
