// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Leetcode Wizard
 * Leetcode Wizard API Description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface LandingPageStatsResponseDto
 */
export interface LandingPageStatsResponseDto {
    /**
     *
     * @type {number}
     * @memberof LandingPageStatsResponseDto
     */
    users: number;
}

/**
 * Check if a given object implements the LandingPageStatsResponseDto interface.
 */
export function instanceOfLandingPageStatsResponseDto(
    value: object,
): value is LandingPageStatsResponseDto {
    if (!('users' in value) || value['users'] === undefined) return false;
    return true;
}

export function LandingPageStatsResponseDtoFromJSON(json: any): LandingPageStatsResponseDto {
    return LandingPageStatsResponseDtoFromJSONTyped(json, false);
}

export function LandingPageStatsResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): LandingPageStatsResponseDto {
    if (json == null) {
        return json;
    }
    return {
        users: json['users'],
    };
}

export function LandingPageStatsResponseDtoToJSON(value?: LandingPageStatsResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        users: value['users'],
    };
}
