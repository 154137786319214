const AUTODETECTED_LEETCODE_WEBSITES = [
    'leetcode',
    'leet code',
    'hackerrank',
    'hacker rank',
    'visualstudiocode',
    'visual studio code',
    'vscode',
    'coderpad',
    'coder pad',
    'codesignal',
    'code signal',
];

export { AUTODETECTED_LEETCODE_WEBSITES };
