import { apiService } from '../../services/ApiService.ts';

export const submitUserMessageAction = async (messageText: string, emailAddress?: string) => {
    await apiService.getApi().indexUserMessageV1({
        userMessageRequestDto: {
            messageText,
            emailAddress,
        },
    });
};
