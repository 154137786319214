import { AuthenticationService } from '../../services/AuthenticationService.ts';
import { useUserInfoStore } from '../../stores/UserInfoStore.ts';
import { useSettingsStore } from '../../stores/SettingsStore.ts';
import { eventEmitterUtil } from '../../utils/EventEmitterUtil.ts';
import { apiService } from '../../services/ApiService.ts';
import { SubscriptionTier } from '../../types/user/SubscriptionTier.ts';
import { useAuthStore } from '../../stores/AuthStore.ts';
import { wsAuthLogIn } from '../../ws/requests/WsAuthLogIn.ts';
import { useUiStore } from '../../stores/UiStore.ts';
import { AI_MODEL } from '../../constants/AiModel.ts';

/**
 * @throws {Error} On API error response
 */
export const registerAction = async (
    username: string,
    password: string,
    referralSource?: string,
) => {
    const apiResponse = await apiService.getApi().authRegisterV1({
        registerRequestDto: {
            username,
            password,
            referralSource,
        },
    });

    const { refreshToken, accessTokenExpiresIn, fingerprintHash, accessToken } =
        apiResponse.tokenData;

    AuthenticationService.instance.storeTokenData(
        accessToken,
        refreshToken,
        fingerprintHash,
        accessTokenExpiresIn,
    );

    const authStore = useAuthStore();
    authStore.isLoggedIn = true;

    const { creditsRemaining, username: userUsername, subscriptionTier } = apiResponse.userData;

    const userInfoStore = useUserInfoStore();
    userInfoStore.username = userUsername;
    userInfoStore.creditsRemaining = creditsRemaining;
    userInfoStore.subscriptionTier = subscriptionTier as SubscriptionTier;

    const settingsStore = useSettingsStore();
    settingsStore.rememberedUsername = userUsername;

    if (userInfoStore.subscriptionTier === SubscriptionTier.Trial) {
        settingsStore.activeAiModel = AI_MODEL['gpt-4o'];
    }

    // Start onboarding
    settingsStore.isOnboarding = true;

    const uiStore = useUiStore();
    wsAuthLogIn(accessToken, !uiStore.isInsideWebView);

    eventEmitterUtil.emit('login');
};
