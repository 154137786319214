import { AUTODETECTED_LEETCODE_WEBSITES } from '../constants/AutodetectedLeetcodeWebsites.ts';

export const isSourceAutoDetected = (inputName: string): boolean => {
    for (const autoDetectedSource of AUTODETECTED_LEETCODE_WEBSITES) {
        if (
            inputName.includes('leetcodewizard') ||
            inputName.includes('Leetcode Wizard') ||
            inputName.includes('wizard') ||
            inputName.includes('lcw')
        ) {
            continue;
        }

        if (inputName.toLowerCase().includes(autoDetectedSource)) {
            return true;
        }
    }

    return false;
};
