// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Leetcode Wizard
 * Leetcode Wizard API Description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CreateAffiliateRequestDto
 */
export interface CreateAffiliateRequestDto {
    /**
     *
     * @type {string}
     * @memberof CreateAffiliateRequestDto
     */
    code: string;
    /**
     *
     * @type {string}
     * @memberof CreateAffiliateRequestDto
     */
    emailAddress: string;
    /**
     *
     * @type {boolean}
     * @memberof CreateAffiliateRequestDto
     */
    notifyOnRegistration: boolean;
    /**
     *
     * @type {boolean}
     * @memberof CreateAffiliateRequestDto
     */
    notifyOnSubscription: boolean;
}

/**
 * Check if a given object implements the CreateAffiliateRequestDto interface.
 */
export function instanceOfCreateAffiliateRequestDto(
    value: object,
): value is CreateAffiliateRequestDto {
    if (!('code' in value) || value['code'] === undefined) return false;
    if (!('emailAddress' in value) || value['emailAddress'] === undefined) return false;
    if (!('notifyOnRegistration' in value) || value['notifyOnRegistration'] === undefined)
        return false;
    if (!('notifyOnSubscription' in value) || value['notifyOnSubscription'] === undefined)
        return false;
    return true;
}

export function CreateAffiliateRequestDtoFromJSON(json: any): CreateAffiliateRequestDto {
    return CreateAffiliateRequestDtoFromJSONTyped(json, false);
}

export function CreateAffiliateRequestDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): CreateAffiliateRequestDto {
    if (json == null) {
        return json;
    }
    return {
        code: json['code'],
        emailAddress: json['emailAddress'],
        notifyOnRegistration: json['notifyOnRegistration'],
        notifyOnSubscription: json['notifyOnSubscription'],
    };
}

export function CreateAffiliateRequestDtoToJSON(value?: CreateAffiliateRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        code: value['code'],
        emailAddress: value['emailAddress'],
        notifyOnRegistration: value['notifyOnRegistration'],
        notifyOnSubscription: value['notifyOnSubscription'],
    };
}
