<script setup lang="ts">
import './../../assets/theme/stackoverflow-dark.css';
import './../../assets/theme/stackoverflow-light.css';

import hljs from 'highlight.js';
import { onMounted, ref, watch } from 'vue';
import { useSettingsStore } from '../../stores/SettingsStore.ts';

const settingsStore = useSettingsStore();

const props = defineProps<{
    content: string;
    highlightJsLanguage: string;
}>();

const codeOutputElement = ref<HTMLDivElement | null>(null);

const formattedContent = ref('');

const cleanCodeString = (str: string) => {
    // Remove outer brackets and language specifier
    const cleaned = str.replace(/^```[a-z]*\n|```$/g, '');
    // Trim leading and trailing newline characters
    return cleaned.trim();
};

const formatCode = () => {
    if (codeOutputElement.value) {
        const result = hljs.highlight(cleanCodeString(props.content), {
            language: props.highlightJsLanguage,
        });

        formattedContent.value = result.value;
    }
};

watch(
    () => [props.content],
    async () => {
        formatCode();
    },
);

onMounted(() => {
    formatCode();
});
</script>

<template>
    <div
        :class="{
            'theme-stackoverflow-dark': settingsStore.darkModeEnabled,
            'theme-stackoverflow-light': !settingsStore.darkModeEnabled,
        }"
    >
        <pre><code ref="codeOutputElement" class="code-output hljs" v-html="formattedContent"></code></pre>
    </div>
</template>

<style scoped>
.code-output {
    @apply bg-card p-4;
    font-size: 0.9rem;
    white-space: pre-wrap;
}
</style>
