<script setup lang="ts">
import { useHostStore } from '../stores/HostStore.ts';
import { useSettingsStore } from '../stores/SettingsStore.ts';
import { InputSource } from '../types/electron/InputSource.ts';
import { onMounted, onUnmounted, reactive } from 'vue';
import { ProgrammingLanguage } from '../types/ProgrammingLanguage.ts';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';
import { Hotkey } from '../types/Hotkey.ts';
import HotkeyIndicatorInline from '../components/hotkey/HotkeyIndicatorInline.vue';
import { Check, ChevronsUpDown, CircleCheckBig, CircleAlert } from 'lucide-vue-next';
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from '../components/ui/command';
import { cn } from '../utils/AppUtil.ts';
import { Button } from '../components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '../components/ui/popover';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '../components/ui/select';
import { requestPermissionStatusAction } from '../actions/electron/RequestPermissionStatusAction.ts';
import { requestSourcesAction } from '../actions/electron/RequestSourcesAction.ts';
import { openPermissionWindowAction } from '../actions/electron/OpenPermissionWindowAction.ts';
import { setInputSourceAction } from '../actions/solve/SetInputSourceAction.ts';
import { eventEmitterUtil } from '../utils/EventEmitterUtil.ts';
import { isSourceAutoDetected } from '../utils/AutoDetectUtil.ts';

const settingsStore = useSettingsStore();
const hostStore = useHostStore();

interface ComponentState {
    onboardingStep: number;
    isProgrammingLanguagePopoverOpen: boolean;
    hasMissingScreenPermission: boolean;
    programmingLanguageValue: ProgrammingLanguage;
    inputSourceValue: InputSource | null;
}

const state: ComponentState = reactive({
    onboardingStep: 0,
    isProgrammingLanguagePopoverOpen: false,
    hasMissingScreenPermission: false,
    programmingLanguageValue: ProgrammingLanguage['Python3'],
    inputSourceValue: null,
});

/* eslint-disable @typescript-eslint/no-explicit-any */
const setProgrammingLanguage = (language: any) => {
    state.programmingLanguageValue = language as ProgrammingLanguage;
};

const setInputSource = (inputSourceId: string) => {
    const inputSource = hostStore.sources.find(
        (source: InputSource) => source.id === inputSourceId,
    );
    if (inputSource) {
        state.inputSourceValue = inputSource;
    }
};

const finishProgrammingLanguageStep = () => {
    settingsStore.programmingLanguage = state.programmingLanguageValue;
    state.onboardingStep = 1;
};

const finishOpenExampleProblemStep = () => {
    state.onboardingStep = 2;
};

const finishInputSourceStep = () => {
    if (state.inputSourceValue) {
        setInputSourceAction(state.inputSourceValue);
    }
    state.onboardingStep = 3;
    settingsStore.isOnboarding = false;
};

const backToStep = (step: number) => {
    state.onboardingStep = step;
    settingsStore.isOnboarding = true;
};

const getSources = async () => {
    requestPermissionStatusAction();
    void requestSourcesAction();
};

const getSourcesDelayed = async () => {
    setTimeout(() => {
        getSources();
    }, 500);
};

const openPermissionWindow = () => {
    openPermissionWindowAction();
};

const onRequestPermissionStatusResponse = (status: { screen: boolean }) => {
    if (!status.screen) {
        state.hasMissingScreenPermission = true;
    }
};

onMounted(async () => {
    eventEmitterUtil.addListener(
        'onRequestPermissionStatusResponse',
        onRequestPermissionStatusResponse,
    );

    state.programmingLanguageValue = settingsStore.programmingLanguage;

    await getSources();
});

onUnmounted(() => {
    eventEmitterUtil.removeListener(
        'onRequestPermissionStatusResponse',
        onRequestPermissionStatusResponse,
    );
});
</script>

<template>
    <div class="custom-scrollbar h-full overflow-y-scroll p-2">
        <!-- Onboarding step 1/4: Programming language -->
        <Card
            class="onboarding-card"
            :class="{ 'onboarding-card--disabled': state.onboardingStep !== 0 }"
        >
            <CardHeader class="onboarding-card__header">
                <CardTitle class="onboarding-card__title">
                    <div>
                        <span class="onboarding-card__title__step">Step 1 of 4:</span>&nbsp;
                        Programming language
                    </div>
                    <Button
                        v-if="state.onboardingStep > 0"
                        class="ml-auto"
                        type="button"
                        variant="secondary"
                        size="sm"
                        @click="backToStep(0)"
                        >Back</Button
                    >
                </CardTitle>
            </CardHeader>
            <CardContent v-show="state.onboardingStep === 0" class="p-4 text-sm">
                <p class="mb-3 text-muted-foreground">
                    Please select the programming language you are most comfortable with or will be
                    using during your interviews:
                </p>
                <div class="flex w-full items-center gap-3">
                    <Popover v-model:open="state.isProgrammingLanguagePopoverOpen">
                        <PopoverTrigger as-child>
                            <Button
                                variant="outline"
                                role="combobox"
                                :aria-expanded="state.isProgrammingLanguagePopoverOpen"
                                class="w-full justify-between"
                            >
                                {{ state.programmingLanguageValue ?? 'Select language...' }}

                                <ChevronsUpDown class="ml-2 h-4 w-4 shrink-0 opacity-50" />
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent class="w-[400px] p-0" align="start">
                            <Command @update:model-value="setProgrammingLanguage">
                                <CommandInput placeholder="Search language..." />
                                <CommandEmpty>No languages found.</CommandEmpty>
                                <CommandList>
                                    <CommandGroup>
                                        <CommandItem
                                            v-for="language in Object.values(
                                                ProgrammingLanguage,
                                            ).filter((v) => isNaN(Number(v)))"
                                            :key="language"
                                            :value="language"
                                            class="hover:cursor-pointer"
                                            @select="state.isProgrammingLanguagePopoverOpen = false"
                                        >
                                            <Check
                                                :class="
                                                    cn(
                                                        'mr-2 h-4 w-4',
                                                        state.programmingLanguageValue.toString() ===
                                                            language
                                                            ? 'opacity-100'
                                                            : 'opacity-0',
                                                    )
                                                "
                                            />
                                            {{ language }}
                                        </CommandItem>
                                    </CommandGroup>
                                </CommandList>
                            </Command>
                        </PopoverContent>
                    </Popover>
                </div>
                <p class="mt-3 text-muted-foreground">
                    You can always change this later in the options menu (Options -> Set Language).
                </p>
                <div class="mt-5 flex justify-end">
                    <Button type="button" variant="success" @click="finishProgrammingLanguageStep">
                        Next step
                    </Button>
                </div>
            </CardContent>
        </Card>

        <!-- Onboarding step 2/4: Open example problem -->
        <Card
            class="onboarding-card"
            :class="{ 'onboarding-card--disabled': state.onboardingStep !== 1 }"
        >
            <CardHeader class="onboarding-card__header">
                <CardTitle class="onboarding-card__title">
                    <div>
                        <span class="onboarding-card__title__step">Step 2 of 4:</span>&nbsp; Open
                        example problem
                    </div>
                    <Button
                        v-if="state.onboardingStep > 1"
                        class="ml-auto"
                        type="button"
                        variant="secondary"
                        size="sm"
                        @click="backToStep(1)"
                        >Back</Button
                    >
                </CardTitle>
            </CardHeader>
            <CardContent v-show="state.onboardingStep === 1" class="p-4 text-sm">
                <p class="mb-3 text-muted-foreground">
                    Click
                    <a
                        href="https://leetcode.com/problems/two-sum/"
                        target="_blank"
                        class="text-blue-500 hover:underline"
                        @click="getSourcesDelayed"
                        >here</a
                    >
                    to open an example problem in your browser.
                </p>
                <p class="flex items-center text-muted-foreground">
                    <CircleAlert class="me-1 inline h-4 w-4" />
                    Make sure to keep the opened browser window visible and do not minimize it.
                </p>
                <div class="mt-5 flex items-center justify-end">
                    <Button type="button" variant="success" @click="finishOpenExampleProblemStep">
                        Next step
                    </Button>
                </div>
            </CardContent>
        </Card>

        <!-- Onboarding step 3/4: Input source -->
        <Card
            class="onboarding-card"
            :class="{ 'onboarding-card--disabled': state.onboardingStep !== 2 }"
        >
            <CardHeader class="onboarding-card__header">
                <CardTitle class="onboarding-card__title">
                    <div>
                        <span class="onboarding-card__title__step">Step 3 of 4:</span>&nbsp; Input
                        source
                    </div>
                    <Button
                        v-if="state.onboardingStep > 2"
                        class="ml-auto"
                        type="button"
                        variant="secondary"
                        size="sm"
                        @click="backToStep(2)"
                        >Back</Button
                    >
                </CardTitle>
            </CardHeader>
            <CardContent v-show="state.onboardingStep === 2" class="p-4 text-sm">
                <div v-if="state.hasMissingScreenPermission">
                    <div class="text-center text-red-700">
                        Leetcode Wizard needs permission to view your screen, click
                        <span class="underline hover:cursor-pointer" @click="openPermissionWindow"
                            >here</span
                        >
                        to open your permission settings.
                    </div>
                </div>

                <div v-else>
                    <p class="mb-3 text-muted-foreground">
                        Please select the opened browser window in the list down below (press
                        "Refresh sources" if it's not there yet):
                    </p>

                    <div class="flex w-full items-center gap-3">
                        <Select
                            :value="state.inputSourceValue"
                            @update:model-value="setInputSource"
                        >
                            <SelectTrigger
                                class="border-2 focus:ring-0"
                                :class="{
                                    'border-red-600': !state.inputSourceValue,
                                    'border-green-600': state.inputSourceValue,
                                }"
                            >
                                <SelectValue placeholder="Select input source..." />
                            </SelectTrigger>
                            <SelectContent>
                                <template v-if="hostStore.sources.length > 0">
                                    <SelectItem
                                        v-for="source in hostStore.sources"
                                        :key="source.id"
                                        class="hover:cursor-pointer"
                                        :disabled="hostStore.sourcesLoading"
                                        :value="source.id"
                                        :class="{
                                            'border border-green-500': isSourceAutoDetected(
                                                source.name,
                                            ),
                                        }"
                                    >
                                        <div class="flex items-center">
                                            <img
                                                :src="source.thumbnail"
                                                alt="Thumbnail"
                                                class="me-2 h-12 w-20 rounded"
                                            />
                                            <span class="max-w-48 truncate">
                                                {{ source.name }}
                                            </span>
                                        </div>
                                    </SelectItem>
                                </template>
                                <SelectItem v-else disabled value="0">
                                    No input sources found, please check if Leetcode Wizard has the
                                    right permissions.
                                </SelectItem>
                            </SelectContent>
                        </Select>
                        <Button
                            type="button"
                            variant="secondary"
                            :disabled="hostStore.sourcesLoading"
                            @click="getSources"
                            >Refresh sources</Button
                        >
                    </div>
                    <p class="mt-3 text-muted-foreground">
                        Make sure the selected input source is
                        <span class="underline">fully visible</span> on your screen and
                        <span class="underline">not minimized</span>.
                    </p>
                </div>
                <div class="mt-5 flex items-center justify-end">
                    <Button
                        type="button"
                        variant="success"
                        :disabled="state.inputSourceValue === null"
                        @click="finishInputSourceStep"
                    >
                        Next step
                    </Button>
                </div>
            </CardContent>
        </Card>

        <!-- Onboarding step 4/4: First solve -->
        <Card
            class="onboarding-card"
            :class="{ 'onboarding-card--disabled': state.onboardingStep !== 3 }"
        >
            <CardHeader class="onboarding-card__header">
                <CardTitle class="onboarding-card__title">
                    <div>
                        <span class="onboarding-card__title__step">Step 4 of 4:</span>&nbsp; Your
                        first solve
                    </div>
                </CardTitle>
            </CardHeader>
            <CardContent v-show="state.onboardingStep === 3" class="p-4 text-sm">
                <p class="mb-3 text-success">
                    <CircleCheckBig class="me-0.5 mt-[-3px] inline h-5 w-5" />
                    Everything is ready!
                </p>

                <p class="mb-3 text-muted-foreground">
                    Programming language:
                    <span class="font-bold">{{ settingsStore.programmingLanguage }}</span
                    ><br />
                    Input source:
                    <span class="font-bold">{{ hostStore.selectedSourceName }}</span>
                </p>

                <p class="mb-3 text-muted-foreground">
                    Click the big green "Solve" button all the way in the top or press
                    <HotkeyIndicatorInline
                        :hotkey="Hotkey.Solve"
                        class="h-[1.25rem] items-center justify-center overflow-hidden rounded border border-muted-foreground px-1 py-0.5 text-xs tracking-wide"
                    />
                    to finish this setup.
                </p>
            </CardContent>
        </Card>
    </div>
</template>

<style scoped>
.onboarding-card {
    @apply mb-2 select-none rounded;
}

.onboarding-card--disabled {
    @apply text-gray-700 hover:cursor-not-allowed;
}

.onboarding-card__header {
    @apply border-b p-4;
}

.onboarding-card__title {
    @apply flex justify-between text-lg;
}

.onboarding-card__title__step {
    @apply text-muted-foreground;
}

.onboarding-card--disabled .onboarding-card__header {
    @apply border-none;
}

.onboarding-card--disabled .onboarding-card__title {
    @apply text-gray-700;
}

.onboarding-card--disabled .onboarding-card__title__step {
    @apply text-gray-500 dark:text-gray-700;
}

button[role='combobox'] img {
    display: none !important;
}
</style>
