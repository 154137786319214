import { useUiStore } from '../../stores/UiStore.ts';
import { createTextToast } from '../../utils/ToastUtil.ts';
import { apiService } from '../../services/ApiService.ts';
import { RequestTransferTokenResponseDto } from '../../../@generated/api-client';

export const transferToBrowserAction = async (redirectUrl: string) => {
    const uiStore = useUiStore();
    uiStore.isFullLoading = true;

    let apiResponse: RequestTransferTokenResponseDto;
    try {
        apiResponse = await apiService.getApi().authRequestTransferTokenV1({
            requestTransferTokenRequestDto: {
                redirectUrl,
            },
        });
    } catch {
        uiStore.isFullLoading = false;

        createTextToast(
            'Failed to request transfer token',
            'Please try again',
            'destructive',
            false,
        );

        return;
    }

    window.open(
        `${import.meta.env.VITE_WEB_VIEW_BASE_URL}/web-view/transfer?t=${apiResponse.token}`,
        '_blank',
    );

    uiStore.isFullLoading = false;
};
