import { useClientStore } from '../../stores/ClientStore.ts';
import { SolveAlgorithm } from '../../types/solve/SolveAlgorithm.ts';
import { WebSocketService } from '../../ws/WebSocketService.ts';
import { handleApiError } from '../../utils/FormUtil.ts';
import { apiService } from '../../services/ApiService.ts';
import { useHostStore } from '../../stores/HostStore.ts';
import { generateCodeStreamId } from '../../ws/id/GenerateCodeStreamId.ts';
import { useUiStore } from '../../stores/UiStore.ts';
import { generateTestsStreamId } from '../../ws/id/GenerateTestsStreamId.ts';
import { generateComplexityStreamId } from '../../ws/id/GenerateComplexityStreamId.ts';
import { useSettingsStore } from '../../stores/SettingsStore.ts';
import { AI_MODEL } from '../../constants/AiModel.ts';
import { createTextToast } from '../../utils/ToastUtil.ts';

export const requestCodeOutputAction = async (solveId: string, algorithmId: string) => {
    const uiStore = useUiStore();
    if (uiStore.isInsideWebView) {
        console.debug(`requestCodeOutputAction called from inside web view, ignoring...`);
        return;
    }

    const clientStore = useClientStore();

    const codeStreamId = generateCodeStreamId(solveId, algorithmId);
    clientStore.streamsWaiting = [...clientStore.streamsWaiting, codeStreamId];

    const algorithm = Object.values(clientStore.solveAlgorithms).find(
        (algorithm: SolveAlgorithm) => {
            return algorithm.id === algorithmId;
        },
    );
    if (algorithm === undefined) {
        throw new Error(`Unable to find algorithm with supplied algorithmId (${algorithmId})`);
    }

    WebSocketService.instance.verifyConnection();

    const programmingLanguage = clientStore.solveProgrammingLanguage[solveId];

    if (!programmingLanguage) {
        throw new Error(
            `No programming language found in client store for supplied solveId (${solveId})`,
        );
    }

    const hostStore = useHostStore();

    const activeImageData = hostStore.activeImageData;
    if (!activeImageData) {
        throw new Error('No active image data found in host store');
    }

    if (activeImageData === 'data:image/png;base64,') {
        createTextToast(
            'Invalid input',
            'Please select a different input source (Options -> Input source).',
            'destructive',
            true,
        );

        return;
    }

    const testsStreamId = generateTestsStreamId(solveId, algorithmId);
    const complexityStreamId = generateComplexityStreamId(solveId, algorithmId);

    const settingsStore = useSettingsStore();

    setTimeout(
        () => {
            if (
                clientStore.streams[codeStreamId] === undefined ||
                clientStore.streams[codeStreamId].length <= 0
            ) {
                clientStore.streamsErrored = [
                    ...clientStore.streamsErrored,
                    codeStreamId,
                    testsStreamId,
                    complexityStreamId,
                ];
            }
        },
        settingsStore.activeAiModel === AI_MODEL['o1-mini'] ? 60_000 : 40_000,
    );

    setTimeout(
        () => {
            if (
                clientStore.streams[testsStreamId] === undefined ||
                clientStore.streams[testsStreamId].length <= 0
            ) {
                clientStore.streamCancelled = [...clientStore.streamCancelled, testsStreamId];
            }

            if (
                clientStore.streams[complexityStreamId] === undefined ||
                clientStore.streams[complexityStreamId].length <= 0
            ) {
                clientStore.streamCancelled = [...clientStore.streamCancelled, complexityStreamId];
            }
        },
        settingsStore.activeAiModel === AI_MODEL['o1-mini'] ? 60_000 : 40_000,
    );

    try {
        await apiService.getApi().solveCodeV1({
            solveCodeRequestDto: {
                imageData: activeImageData,
                problemExtract: hostStore.activeProblemExtractData ?? '',
                exampleCodeExtract: hostStore.activeExampleCodeExtractData ?? '',
                algorithmName: algorithm.algorithmName,
                programmingLanguage: programmingLanguage,
                solveId: solveId,
                streamId: codeStreamId,
                aiModel: settingsStore.activeAiModel ?? AI_MODEL['gpt-4o'],
            },
        });
    } catch (error) {
        await handleApiError(error, undefined, true);

        clientStore.streamsErrored = [
            ...clientStore.streamsErrored,
            codeStreamId,
            testsStreamId,
            complexityStreamId,
        ];

        // no return
    }

    return true;
};
