// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Leetcode Wizard
 * Leetcode Wizard API Description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CreateAffiliateResponseDto
 */
export interface CreateAffiliateResponseDto {
    /**
     *
     * @type {string}
     * @memberof CreateAffiliateResponseDto
     */
    emailAddress: string;
    /**
     *
     * @type {string}
     * @memberof CreateAffiliateResponseDto
     */
    code: string;
}

/**
 * Check if a given object implements the CreateAffiliateResponseDto interface.
 */
export function instanceOfCreateAffiliateResponseDto(
    value: object,
): value is CreateAffiliateResponseDto {
    if (!('emailAddress' in value) || value['emailAddress'] === undefined) return false;
    if (!('code' in value) || value['code'] === undefined) return false;
    return true;
}

export function CreateAffiliateResponseDtoFromJSON(json: any): CreateAffiliateResponseDto {
    return CreateAffiliateResponseDtoFromJSONTyped(json, false);
}

export function CreateAffiliateResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): CreateAffiliateResponseDto {
    if (json == null) {
        return json;
    }
    return {
        emailAddress: json['emailAddress'],
        code: json['code'],
    };
}

export function CreateAffiliateResponseDtoToJSON(value?: CreateAffiliateResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        emailAddress: value['emailAddress'],
        code: value['code'],
    };
}
