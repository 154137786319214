<script setup lang="ts">
import { useSettingsStore } from '../../stores/SettingsStore.ts';
import { onMounted, ref } from 'vue';
import { watch } from 'vue';
import { Slider } from '../ui/slider';
import { MenubarItem, MenubarSub, MenubarSubContent, MenubarSubTrigger } from '../ui/menubar';
import { setAppOpacityAction } from '../../actions/electron/SetAppOpacityAction.ts';

const settingsStore = useSettingsStore();

const currentValue = ref<number[]>([100]);

watch(currentValue, () => {
    settingsStore.opacity = Number(currentValue.value[0]);
    setAppOpacityAction(Number(currentValue.value[0]));
});

onMounted(() => {
    currentValue.value = [settingsStore.opacity];
});
</script>

<template>
    <MenubarSub>
        <MenubarSubTrigger>Opacity</MenubarSubTrigger>
        <MenubarSubContent>
            <MenubarItem class="h-8 px-3" @select="(event: Event) => event.preventDefault()">
                <Slider v-model="currentValue" :min="15" :max="100" :step="5" />
            </MenubarItem>
        </MenubarSubContent>
    </MenubarSub>
</template>
