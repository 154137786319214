import { AuthenticationService } from '../../services/AuthenticationService.ts';
import { useUserInfoStore } from '../../stores/UserInfoStore.ts';
import { useSettingsStore } from '../../stores/SettingsStore.ts';
import { eventEmitterUtil } from '../../utils/EventEmitterUtil.ts';
import { wsAuthLogIn } from '../../ws/requests/WsAuthLogIn.ts';
import { SubscriptionTier } from '../../types/user/SubscriptionTier.ts';
import { apiService } from '../../services/ApiService.ts';
import { resetAction } from '../app/ResetAction.ts';
import { useAuthStore } from '../../stores/AuthStore.ts';
import { createTextToast } from '../../utils/ToastUtil.ts';
import { useUiStore } from '../../stores/UiStore.ts';
import { AI_MODEL } from '../../constants/AiModel.ts';

/**
 * @throws {Error} On API error response
 */
export const logInAction = async (username: string, password: string) => {
    const apiResponse = await apiService.getApi().authLoginV1({
        loginRequestDto: {
            username,
            password,
        },
    });

    const { creditsRemaining, username: userUsername, subscriptionTier } = apiResponse.userData;

    // Web view trial check
    const uiStore = useUiStore();
    if (uiStore.isInsideWebView && subscriptionTier === SubscriptionTier.Trial) {
        createTextToast(
            'Web view is not available to trial users.',
            'Please upgrade your account to continue.',
            'destructive',
            false,
        );

        return;
    }

    await resetAction(false);

    const { refreshToken, accessTokenExpiresIn, fingerprintHash, accessToken } =
        apiResponse.tokenData;

    AuthenticationService.instance.storeTokenData(
        accessToken,
        refreshToken,
        fingerprintHash,
        accessTokenExpiresIn,
    );

    const authStore = useAuthStore();
    authStore.isLoggedIn = true;

    const userInfoStore = useUserInfoStore();
    userInfoStore.username = userUsername;
    userInfoStore.creditsRemaining = creditsRemaining;
    userInfoStore.subscriptionTier = subscriptionTier as SubscriptionTier;

    const settingsStore = useSettingsStore();
    settingsStore.rememberedUsername = userUsername;

    if (userInfoStore.subscriptionTier === SubscriptionTier.Trial) {
        settingsStore.activeAiModel = AI_MODEL['gpt-4o'];
    }

    // End onboarding
    settingsStore.isOnboarding = false;

    wsAuthLogIn(accessToken, !uiStore.isInsideWebView);

    eventEmitterUtil.emit('login');
};
