import { acceptHMRUpdate, defineStore, storeToRefs } from 'pinia';
import { ProgrammingLanguage } from '../types/ProgrammingLanguage.ts';
import { SolveAlgorithm } from '../types/solve/SolveAlgorithm.ts';
import { syncSetClientStoreAction } from '../actions/sync/SyncSetClientStoreAction.ts';
import { watch } from 'vue';

interface State {
    // Solve
    availableSolveIds: string[]; // Every solve action has a unique ID. This list contains all loaded solves.
    activeSolveId: string | null; // The ID of the active solve action.
    activeAlgorithmId: string | null; // The ID of the active (last selected) algorithm.
    solveProgrammingLanguage: { [key: string]: ProgrammingLanguage }; // A map of the configured programming language for each solve.
    solveAlgorithms: { [key: string]: SolveAlgorithm }; // A map of the loaded algorithms keyed by their ID.

    // Streams
    streams: { [key: string]: string };
    streamsWaiting: string[];
    streamsInProgress: string[];
    streamsErrored: string[];
    streamCancelled: string[];

    // Navigation
    navigationVerticalIndexes: { [key: string]: number };
}

export const useClientStore = defineStore('client', {
    state: (): State => {
        return {
            // Solve
            availableSolveIds: [],
            activeAlgorithmId: null,
            activeSolveId: null,
            solveProgrammingLanguage: {},
            solveAlgorithms: {},

            // Streams
            streams: {},
            streamsWaiting: [],
            streamsInProgress: [],
            streamsErrored: [],
            streamCancelled: [],

            // Navigation
            navigationVerticalIndexes: {},
        };
    },
    persist: {
        storage: sessionStorage,
    },
});

export const registerClientStoreWatcher = () => {
    const clientStore = useClientStore();
    const {
        availableSolveIds,
        activeSolveId,
        activeAlgorithmId,
        solveProgrammingLanguage,
        streamsWaiting,
        streamsInProgress,
        streamsErrored,
        navigationVerticalIndexes,
    } = storeToRefs(clientStore);

    watch(
        [
            availableSolveIds,
            activeSolveId,
            activeAlgorithmId,
            solveProgrammingLanguage,
            streamsWaiting,
            streamsInProgress,
            streamsErrored,
            navigationVerticalIndexes,
        ],
        async ([
            newAvailableSolveIds,
            newActiveSolveId,
            newActiveAlgorithmId,
            newSolveProgrammingLanguage,
            newStreamsWaiting,
            newStreamsInProgress,
            newStreamsErrored,
            newNavigationVerticalIndexes,
        ]) => {
            syncSetClientStoreAction({
                availableSolveIds: newAvailableSolveIds,
                activeSolveId: newActiveSolveId,
                activeAlgorithmId: newActiveAlgorithmId,
                solveProgrammingLanguage: newSolveProgrammingLanguage,

                streamsWaiting: newStreamsWaiting,
                streamsInProgress: newStreamsInProgress,
                streamsErrored: newStreamsErrored,

                navigationVerticalIndexes: newNavigationVerticalIndexes,
            });
        },
    );
};

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useClientStore, import.meta.hot));
}
