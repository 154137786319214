import { useUserInfoStore } from '../../stores/UserInfoStore.ts';
import { SubscriptionTier } from '../../types/user/SubscriptionTier.ts';
import { createTextToast } from '../../utils/ToastUtil.ts';
import { transferToBrowserAction } from '../auth/TransferToBrowserAction.ts';
import { useUiStore } from '../../stores/UiStore.ts';

export const openWebViewAction = async () => {
    const uiStore = useUiStore();
    if (uiStore.isInsideWebView) {
        console.debug(`openWebViewAction called from inside web view, ignoring...`);
        return;
    }

    const userInfoStore = useUserInfoStore();
    if (userInfoStore.subscriptionTier === SubscriptionTier.Trial) {
        createTextToast(
            'Web View is unavailable during trial.',
            'Subscribe to use the Leetcode Wizard web view.',
            'destructive',
            false,
        );

        return;
    }

    await transferToBrowserAction('/');
};
