import { useHostStore } from '../../stores/HostStore.ts';
import { InputSource } from '../../types/electron/InputSource.ts';
import { useUiStore } from '../../stores/UiStore.ts';

const setInputSourceAction = async (source: InputSource) => {
    const uiStore = useUiStore();
    if (uiStore.isInsideWebView) {
        console.debug(`setInputSourceAction called from inside web view, ignoring...`);
        return;
    }

    const hostStore = useHostStore();
    hostStore.selectedSourceId = source.id;
    hostStore.selectedSourceName = source.name;
    hostStore.lostSourceId = null;
    hostStore.lostSourceName = null;
};

export { setInputSourceAction };
