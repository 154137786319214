import { useSettingsStore } from '../../stores/SettingsStore.ts';

export const setAppAlwaysOnTopAction = async (alwaysOnTop: boolean) => {
    if (!window.electronApi) {
        return;
    }

    const settingsStore = useSettingsStore();
    settingsStore.appAlwaysOnTop = alwaysOnTop;

    window.electronApi.setAlwaysOnTop(alwaysOnTop);
};
