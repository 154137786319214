// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Leetcode Wizard
 * Leetcode Wizard API Description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface RefreshTokenRequestDto
 */
export interface RefreshTokenRequestDto {
    /**
     *
     * @type {string}
     * @memberof RefreshTokenRequestDto
     */
    refreshToken: string;
    /**
     *
     * @type {string}
     * @memberof RefreshTokenRequestDto
     */
    fingerprintHash: string;
}

/**
 * Check if a given object implements the RefreshTokenRequestDto interface.
 */
export function instanceOfRefreshTokenRequestDto(value: object): value is RefreshTokenRequestDto {
    if (!('refreshToken' in value) || value['refreshToken'] === undefined) return false;
    if (!('fingerprintHash' in value) || value['fingerprintHash'] === undefined) return false;
    return true;
}

export function RefreshTokenRequestDtoFromJSON(json: any): RefreshTokenRequestDto {
    return RefreshTokenRequestDtoFromJSONTyped(json, false);
}

export function RefreshTokenRequestDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): RefreshTokenRequestDto {
    if (json == null) {
        return json;
    }
    return {
        refreshToken: json['refreshToken'],
        fingerprintHash: json['fingerprintHash'],
    };
}

export function RefreshTokenRequestDtoToJSON(value?: RefreshTokenRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        refreshToken: value['refreshToken'],
        fingerprintHash: value['fingerprintHash'],
    };
}
