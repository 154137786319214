// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Leetcode Wizard
 * Leetcode Wizard API Description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SolveComplexityRequestDto
 */
export interface SolveComplexityRequestDto {
    /**
     *
     * @type {string}
     * @memberof SolveComplexityRequestDto
     */
    code: string;
    /**
     *
     * @type {string}
     * @memberof SolveComplexityRequestDto
     */
    algorithmName: string;
    /**
     *
     * @type {string}
     * @memberof SolveComplexityRequestDto
     */
    solveId: string;
    /**
     *
     * @type {string}
     * @memberof SolveComplexityRequestDto
     */
    streamId: string;
    /**
     *
     * @type {string}
     * @memberof SolveComplexityRequestDto
     */
    aiModel: SolveComplexityRequestDtoAiModelEnum;
}

/**
 * @export
 */
export const SolveComplexityRequestDtoAiModelEnum = {
    Gpt4o: 'gpt-4o',
    Claude35Sonnet: 'claude-35-sonnet',
    O1Mini: 'o1-mini',
} as const;
export type SolveComplexityRequestDtoAiModelEnum =
    (typeof SolveComplexityRequestDtoAiModelEnum)[keyof typeof SolveComplexityRequestDtoAiModelEnum];

/**
 * Check if a given object implements the SolveComplexityRequestDto interface.
 */
export function instanceOfSolveComplexityRequestDto(
    value: object,
): value is SolveComplexityRequestDto {
    if (!('code' in value) || value['code'] === undefined) return false;
    if (!('algorithmName' in value) || value['algorithmName'] === undefined) return false;
    if (!('solveId' in value) || value['solveId'] === undefined) return false;
    if (!('streamId' in value) || value['streamId'] === undefined) return false;
    if (!('aiModel' in value) || value['aiModel'] === undefined) return false;
    return true;
}

export function SolveComplexityRequestDtoFromJSON(json: any): SolveComplexityRequestDto {
    return SolveComplexityRequestDtoFromJSONTyped(json, false);
}

export function SolveComplexityRequestDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): SolveComplexityRequestDto {
    if (json == null) {
        return json;
    }
    return {
        code: json['code'],
        algorithmName: json['algorithmName'],
        solveId: json['solveId'],
        streamId: json['streamId'],
        aiModel: json['aiModel'],
    };
}

export function SolveComplexityRequestDtoToJSON(value?: SolveComplexityRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        code: value['code'],
        algorithmName: value['algorithmName'],
        solveId: value['solveId'],
        streamId: value['streamId'],
        aiModel: value['aiModel'],
    };
}
