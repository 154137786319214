// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Leetcode Wizard
 * Leetcode Wizard API Description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface IndexResponseDto
 */
export interface IndexResponseDto {
    /**
     *
     * @type {number}
     * @memberof IndexResponseDto
     */
    time: number;
}

/**
 * Check if a given object implements the IndexResponseDto interface.
 */
export function instanceOfIndexResponseDto(value: object): value is IndexResponseDto {
    if (!('time' in value) || value['time'] === undefined) return false;
    return true;
}

export function IndexResponseDtoFromJSON(json: any): IndexResponseDto {
    return IndexResponseDtoFromJSONTyped(json, false);
}

export function IndexResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): IndexResponseDto {
    if (json == null) {
        return json;
    }
    return {
        time: json['time'],
    };
}

export function IndexResponseDtoToJSON(value?: IndexResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        time: value['time'],
    };
}
