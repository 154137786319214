<script setup lang="ts">
import { onMounted, onUnmounted, reactive } from 'vue';
import { eventEmitterUtil } from '../../utils/EventEmitterUtil.ts';
import {
    AlertDialog,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from '../ui/alert-dialog';
import { Button } from '../ui/button';
import { toggleContentProtectionAction } from '../../actions/electron/ToggleContentProtectionAction.ts';

interface ComponentState {
    isOpen: boolean;
}

const state: ComponentState = reactive({
    isOpen: false,
});

const openDialog = () => {
    state.isOpen = true;
};
const closeDialog = () => {
    state.isOpen = false;
};

const continueDisable = () => {
    state.isOpen = false;
    toggleContentProtectionAction(false);
};

onMounted(() => {
    eventEmitterUtil.addListener('showContentProtectionWarning', openDialog);
});

onUnmounted(() => {
    eventEmitterUtil.removeListener('showContentProtectionWarning', openDialog);
});
</script>

<template>
    <AlertDialog :open="state.isOpen">
        <AlertDialogContent>
            <AlertDialogHeader>
                <AlertDialogTitle>Disable content protection</AlertDialogTitle>
                <AlertDialogDescription>
                    <p>
                        Are you sure you want to disable content protection? This will make the app
                        visible in all screenshots and to all screensharing software.
                    </p>

                    <p class="mt-4">
                        You should not disable content protection during real interviews.
                    </p>
                </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
                <AlertDialogCancel @click="closeDialog">Cancel</AlertDialogCancel>
                <Button type="button" variant="destructive" @click="continueDisable">
                    <span>Disable</span>
                </Button>
            </AlertDialogFooter>
        </AlertDialogContent>
    </AlertDialog>
</template>
