import { Hotkey } from '../../types/Hotkey.ts';
import { useUiStore } from '../../stores/UiStore.ts';
import { syncHotkeyAction } from '../sync/SyncHotkeyAction.ts';
import { solveAction } from '../solve/SolveAction.ts';
import { resetAction } from './ResetAction.ts';
import { hideAppAction } from '../electron/HideAppAction.ts';
import { quitAppAction } from '../electron/QuitAppAction.ts';
import { eventEmitterUtil } from '../../utils/EventEmitterUtil.ts';

export const handleShortcutPressAction = async (hotkey: Hotkey) => {
    const uiStore = useUiStore();
    if (uiStore.isInsideWebView) {
        console.debug(`handleShortcutPressAction called from inside web view, ignoring...`);
        return;
    }

    if (uiStore.isFullLoading) {
        console.debug(`handleShortcutPressAction called while in full load state, ignoring...`);
        return;
    }

    eventEmitterUtil.emit('hotkeyPress', hotkey);

    syncHotkeyAction(hotkey);

    switch (hotkey) {
        case Hotkey.Solve:
            await solveAction();
            break;
        case Hotkey.Reset:
            await resetAction(true);
            break;
        case Hotkey.Hide:
            await hideAppAction();
            break;
        case Hotkey.Quit:
            await quitAppAction();
            break;
    }
};
