import { createRouter, createWebHistory } from 'vue-router';
import IndexPage from './pages/IndexPage.vue';
import OnboardingPage from './pages/OnboardingPage.vue';
import LoginPage from './pages/auth/LoginPage.vue';
import RegisterPage from './pages/auth/RegisterPage.vue';
import AlgorithmSelectionPage from './pages/solve/AlgorithmSelectionPage.vue';
import FinalOutputPage from './pages/solve/OutputPage.vue';
import { AuthenticationService } from './services/AuthenticationService.ts';
import { syncSetRouteAction } from './actions/sync/SyncSetRouteAction.ts';
import TransferPage from './pages/web-view/TransferPage.vue';

export const enum ROUTES {
    index = '/',
    onboarding = '/onboarding',
    // Auth
    authLogin = '/auth/login',
    authRegister = '/auth/register',
    // Solve
    solveAlgorithmSelection = '/solve/:solveId/algorithm-selection',
    solveOutput = '/solve/:solveId/:algorithmId/output',
    // Web view
    webViewTransfer = '/web-view/transfer',
}

export const BLOCKED_SYNC_ROUTES = [
    ROUTES.onboarding,
    ROUTES.authLogin,
    ROUTES.authRegister,
    ROUTES.webViewTransfer,
];

const routeAuthGuard = () => {
    const isLoggedIn = AuthenticationService.instance.isLoggedIn();
    if (!isLoggedIn) {
        return { path: ROUTES.authLogin };
    }
};

const desktopAppOnlyGuard = () => {
    if (!window.electronApi) {
        return false;
    }
};

const routes = [
    // Auth
    { name: ROUTES.authLogin, path: ROUTES.authLogin, component: LoginPage },
    {
        name: ROUTES.authRegister,
        path: ROUTES.authRegister,
        component: RegisterPage,
        beforeEnter: [desktopAppOnlyGuard],
    },
    // Index
    { name: ROUTES.index, path: ROUTES.index, component: IndexPage, beforeEnter: [routeAuthGuard] },
    {
        name: ROUTES.onboarding,
        path: ROUTES.onboarding,
        component: OnboardingPage,
        beforeEnter: [routeAuthGuard, desktopAppOnlyGuard],
    },
    // Output
    {
        name: ROUTES.solveAlgorithmSelection,
        path: ROUTES.solveAlgorithmSelection,
        component: AlgorithmSelectionPage,
        beforeEnter: [routeAuthGuard],
    },
    {
        name: ROUTES.solveOutput,
        path: ROUTES.solveOutput,
        component: FinalOutputPage,
        beforeEnter: [routeAuthGuard],
    },
    // Web view
    {
        name: ROUTES.webViewTransfer,
        path: ROUTES.webViewTransfer,
        component: TransferPage,
    },
];

export const router = createRouter({
    history: createWebHistory(),
    routes,
});

export const registerRouterSyncHook = () => {
    router.afterEach((to) => {
        syncSetRouteAction(to.fullPath);
    });
};
