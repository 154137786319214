const modifiers =
    /^(Command|Cmd|Control|Ctrl|CommandOrControl|CmdOrCtrl|Alt|Option|AltGr|Shift|Super)$/;
const keyCodes =
    /^([0-9A-Z)!@#$%^&*(:+<_>?~{|}";=,\-./`[\\\]']|num0*[0-9]|numdec|numadd|numsub|nummult|numdiv|Numlock|F1*[1-9]|F10|F2[0-4]|Plus|Space|Tab|Backspace|Delete|Insert|Return|Enter|Up|Down|Left|Right|Home|End|PageUp|PageDown|Escape|Esc|VolumeUp|VolumeDown|VolumeMute|MediaNextTrack|MediaPreviousTrack|MediaStop|MediaPlayPause|PrintScreen)$/;

const keySymbolMap: { [key: string]: string } = {
    left: '←',
    right: '→',
    up: '↑',
    down: '↓',
    enter: '⏎',
    return: '⏎',
    space: '␣',
    backspace: '⌫',
    delete: '⌦',
    tab: '⇥',
    escape: '⎋',
    esc: '⎋',
    home: '⇱',
    end: '⇲',
    pageup: '⇞',
    pagedown: '⇟',
    insert: '⎀',
    capslock: '⇪',
    numlock: '⇭',
    scrolllock: '⇳',
    pause: '⎉',
    printScreen: '⎙',
    menu: '≣',
    windows: '❖',
    command: '⌘',
    cmd: '⌘',
    option: '⌥',
    altGr: 'AltGr',
    control: 'Ctrl',
    ctrl: 'Ctrl',
    shift: 'Shift',
    alt: 'Alt',
    meta: 'Meta',
    super: 'Super',
    hyper: 'Hyper',
    hn: 'Fn',
    // Function keys
    f1: 'F1',
    f2: 'F2',
    f3: 'F3',
    f4: 'F4',
    f5: 'F5',
    f6: 'F6',
    f7: 'F7',
    f8: 'F8',
    f9: 'F9',
    f10: 'F10',
    f11: 'F11',
    f12: 'F12',
    // Arrow keys
    arrowleft: '←',
    arrowright: '→',
    arrowup: '↑',
    arrowdown: '↓',
    // Numpad
    numdiv: '/',
    nummult: '*',
    numsub: '-',
    numadd: '+',
};

export const isAccelerator = (str: string) => {
    const parts = str.split('+');
    let keyFound = false;

    return parts.every((val, index) => {
        const isKey = keyCodes.test(val);
        const isModifier = modifiers.test(val);
        if (isKey) {
            // Key must be unique
            if (keyFound) return false;
            keyFound = true;
        }

        // Key is required
        if (index === parts.length - 1 && !keyFound) return false;
        return isKey || isModifier;
    });
};

export const acceleratorToLabel = (accelerator: string) => {
    // Split the input string by '+'
    const parts = accelerator.split('+');

    // Trim whitespace from parts
    const trimmedParts = parts.map((part) => part.trim());

    // The key is the last part
    const key = trimmedParts.pop() || '';

    // Modifiers are the rest
    const modifiers = trimmedParts;

    // Replace the key with its symbol if available (case-insensitive)
    const keySymbol = keySymbolMap[key.toLowerCase()] || key;

    // Reconstruct the string
    return [...modifiers, keySymbol].join(' ');
};
