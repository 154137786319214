<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue';
import {
    MenubarCheckboxItem,
    type MenubarCheckboxItemEmits,
    type MenubarCheckboxItemProps,
    MenubarItemIndicator,
    useForwardPropsEmits,
} from 'radix-vue';
import { CheckIcon } from '@radix-icons/vue';
import { cn } from '../../../utils/AppUtil.ts';

const props = defineProps<MenubarCheckboxItemProps & { class?: HTMLAttributes['class'] }>();
const emits = defineEmits<MenubarCheckboxItemEmits>();

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props;

    return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>

<template>
    <MenubarCheckboxItem
        v-bind="forwarded"
        :class="
            cn(
                'relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
                props.class,
            )
        "
    >
        <span class="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
            <MenubarItemIndicator>
                <CheckIcon class="h-4 w-4" />
            </MenubarItemIndicator>
        </span>
        <slot />
    </MenubarCheckboxItem>
</template>
