import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { Ref } from 'vue';
import { useSettingsStore } from '../stores/SettingsStore.ts';

export const cn = (...inputs: ClassValue[]) => {
    return twMerge(clsx(inputs));
};

export const focusRefElement = (ref: Ref) => {
    if (ref.value) {
        ref.value['$el'].focus();
    }
};

export const truncateString = (input: string, length: number) => {
    return input.length > length ? input.slice(0, length - 1).trim() + '...' : input;
};

export const clampNumber = (val: number, min: number, max: number) => {
    return Math.min(Math.max(val, min), max);
};

export const getOs = (): string | null => {
    const userAgent = window.navigator.userAgent.toLowerCase(),
        macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i,
        windowsPlatforms = /(win32|win64|windows|wince)/i,
        iosPlatforms = /(iphone|ipad|ipod)/i;

    let os = null;

    if (macosPlatforms.test(userAgent)) {
        os = 'macos';
    } else if (iosPlatforms.test(userAgent)) {
        os = 'ios';
    } else if (windowsPlatforms.test(userAgent)) {
        os = 'windows';
    } else if (/android/.test(userAgent)) {
        os = 'android';
    } else if (/linux/.test(userAgent)) {
        os = 'linux';
    }

    return os;
};

export const setDarkModeClass = () => {
    const settingsStore = useSettingsStore();

    if (settingsStore.darkModeEnabled) {
        document.documentElement.classList.add('dark');
    } else {
        document.documentElement.classList.remove('dark');
    }
};
