<script setup lang="ts">
import { eventEmitterUtil } from '../../utils/EventEmitterUtil.ts';
import { onMounted, onUnmounted, reactive } from 'vue';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '../ui/dialog';
import { Card, CardDescription, CardHeader, CardTitle } from '../ui/card';
import { Badge } from '../ui/badge';
import { useSettingsStore } from '../../stores/SettingsStore.ts';
import { AI_MODEL } from '../../constants/AiModel.ts';
import { Button } from '../ui/button';

const settingsStore = useSettingsStore();

interface ComponentState {
    isOpen: boolean;
    selectedModel?: AI_MODEL;
}

const state: ComponentState = reactive({
    isOpen: false,
    selectedModel: undefined,
});

const openModal = () => {
    state.isOpen = true;
    state.selectedModel = settingsStore.activeAiModel;
};

const selectModel = (model: AI_MODEL) => {
    state.selectedModel = model;
};

const saveAndClose = () => {
    if (state.selectedModel !== undefined) {
        settingsStore.activeAiModel = state.selectedModel;
    }
    state.isOpen = false;
};

onMounted(() => {
    eventEmitterUtil.addListener('openModelSelectionDialog', openModal);
});

onUnmounted(() => {
    eventEmitterUtil.removeListener('openModelSelectionDialog', openModal);
});
</script>

<template>
    <Dialog v-model:open="state.isOpen">
        <DialogContent class="rounded">
            <DialogHeader class="mb-4">
                <DialogTitle> Configure AI model </DialogTitle>
            </DialogHeader>

            <p class="text-sm dark:text-gray-400">
                Our custom training sets will be applied on the selected base model.
            </p>

            <Card
                class="hover:border-gray-500 dark:bg-gray-900 dark:hover:border-white"
                :class="{
                    'border-green-500': state.selectedModel === AI_MODEL['o1-mini'],
                }"
                @click="selectModel(AI_MODEL['o1-mini'])"
            >
                <CardHeader>
                    <CardTitle>
                        o1-mini
                        <span class="ms-0.5 text-sm text-muted-foreground">by OpenAI</span>
                        <Badge class="ms-2 bg-blue-500 px-1 text-white"> Beta </Badge>
                        <Badge
                            v-if="settingsStore.activeAiModel === AI_MODEL['o1-mini']"
                            class="ms-2 px-1 hover:bg-primary"
                        >
                            Active
                        </Badge>
                    </CardTitle>
                    <CardDescription class="mt-1">
                        A new large language model trained with reinforcement learning to perform
                        complex reasoning. o1 thinks before it answers and can produce a long
                        internal chain of thought before responding.
                        <br /><br />
                        <span class="font-semibold"
                            >For users that want the best possible outputs and are okay with slower
                            responses.</span
                        >
                    </CardDescription>
                </CardHeader>
            </Card>

            <Card
                class="hover:border-gray-500 dark:bg-gray-900 dark:hover:border-white"
                :class="{
                    'border-green-500': state.selectedModel === AI_MODEL['gpt-4o'],
                }"
                @click="selectModel(AI_MODEL['gpt-4o'])"
            >
                <CardHeader>
                    <CardTitle>
                        GPT-4o
                        <span class="ms-0.5 text-sm text-muted-foreground">by OpenAI</span>
                        <Badge
                            v-if="settingsStore.activeAiModel === AI_MODEL['gpt-4o']"
                            class="ms-2 px-1"
                        >
                            Active
                        </Badge>
                    </CardTitle>
                    <CardDescription class="mt-1">
                        A faster, more optimized version of GPT-4 designed to provide efficient,
                        high-quality responses.
                        <br /><br />
                        <span class="font-semibold"
                            >Stable output, recommended for most users.</span
                        >
                    </CardDescription>
                </CardHeader>
            </Card>

            <Card
                class="hover:border-gray-500 dark:bg-gray-900 dark:hover:border-white"
                :class="{
                    'border-green-500': state.selectedModel === AI_MODEL['claude-35-sonnet'],
                }"
                @click="selectModel(AI_MODEL['claude-35-sonnet'])"
            >
                <CardHeader>
                    <CardTitle>
                        Claude 3.5 Sonnet
                        <span class="ms-0.5 text-sm text-muted-foreground"> by Anthropic </span>
                        <Badge
                            v-if="settingsStore.activeAiModel === AI_MODEL['claude-35-sonnet']"
                            class="ms-2 px-1"
                        >
                            Active
                        </Badge>
                    </CardTitle>
                    <CardDescription class="mt-1">
                        Claude 3.5 Sonnet raises the industry bar for intelligence, outperforming
                        competitor models and Claude 3 Opus on a wide range of evaluations.
                        <br /><br />
                        <span class="font-semibold"
                            >More creative output, for users that want to experiment.</span
                        >
                    </CardDescription>
                </CardHeader>
            </Card>

            <DialogFooter>
                <Button type="button" @click="saveAndClose">
                    <span>Save</span>
                </Button>
            </DialogFooter>
        </DialogContent>
    </Dialog>
</template>
