// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Leetcode Wizard
 * Leetcode Wizard API Description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface LoginRequestDto
 */
export interface LoginRequestDto {
    /**
     *
     * @type {string}
     * @memberof LoginRequestDto
     */
    username: string;
    /**
     *
     * @type {string}
     * @memberof LoginRequestDto
     */
    password: string;
}

/**
 * Check if a given object implements the LoginRequestDto interface.
 */
export function instanceOfLoginRequestDto(value: object): value is LoginRequestDto {
    if (!('username' in value) || value['username'] === undefined) return false;
    if (!('password' in value) || value['password'] === undefined) return false;
    return true;
}

export function LoginRequestDtoFromJSON(json: any): LoginRequestDto {
    return LoginRequestDtoFromJSONTyped(json, false);
}

export function LoginRequestDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): LoginRequestDto {
    if (json == null) {
        return json;
    }
    return {
        username: json['username'],
        password: json['password'],
    };
}

export function LoginRequestDtoToJSON(value?: LoginRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        username: value['username'],
        password: value['password'],
    };
}
