import { apiService } from '../../services/ApiService.ts';
import { CreateAnalyticsEventRequestDtoTEnum } from '../../../@generated/api-client';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const createTreasurerInputAction = async (t: string, d: any = undefined) => {
    await apiService.getApi().treasurerV1({
        createAnalyticsEventRequestDto: {
            t: t as CreateAnalyticsEventRequestDtoTEnum,
            d: d,
        },
    });
};
