import { ToastVariants } from '../../components/ui/toast';
import { WebSocketService } from '../../ws/WebSocketService.ts';
import { WS_REQUEST } from '../../ws/WebSocketEvents.ts';
import { STATIC_NAMESPACE_ID } from '../../constants/StaticNamespaceId.ts';
import { useUiStore } from '../../stores/UiStore.ts';

export const syncPushToastAction = (
    title: string,
    description: string | undefined,
    variant: ToastVariants['variant'],
): void => {
    const uiStore = useUiStore();
    if (uiStore.isInsideWebView) {
        throw new Error('syncPushToastAction should not be called from inside the web view');
    }

    WebSocketService.instance.send(WS_REQUEST.sync_pushToast, {
        title: title,
        description: description,
        variant: variant,
        id: STATIC_NAMESPACE_ID,
    });
};
