import { WebSocketService } from '../WebSocketService.ts';
import { WS_REQUEST } from '../WebSocketEvents.ts';
import { v4 as uuidv4 } from 'uuid';

export const wsAuthLogIn = (token: string, isDesktopApp: boolean) => {
    WebSocketService.instance.verifyConnection();

    WebSocketService.instance.send(WS_REQUEST.auth_logIn, {
        id: uuidv4(),
        token: token,
        isDesktopApp: isDesktopApp,
    });
};
