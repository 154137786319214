import { useSettingsStore } from '../../stores/SettingsStore.ts';

export const toggleDarkModeAction = async () => {
    const settingsStore = useSettingsStore();
    settingsStore.darkModeEnabled = !settingsStore.darkModeEnabled;

    if (settingsStore.darkModeEnabled) {
        document.documentElement.classList.add('dark');
    } else {
        document.documentElement.classList.remove('dark');
    }
};
