<script setup lang="ts">
import { reactive } from 'vue';
import { toTypedSchema } from '@vee-validate/zod';
import { z } from 'zod';
import { useForm } from 'vee-validate';
import { handleFormError } from '../../utils/FormUtil.ts';
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from '../ui/dialog';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '../ui/form';
import { FormDescription } from '../ui/form';
import Loader from '../Loader.vue';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Textarea } from '../ui/textarea';
import { submitUserMessageAction } from '../../actions/app/SubmitUserMessageAction.ts';

defineProps<{
    title: string;
}>();

const state = reactive({
    isOpen: false,
    isLoading: false,
});

const formSchema = toTypedSchema(
    z.object({
        _: z.unknown(),
        messageText: z.string().min(10),
        emailAddress: z.string().email().optional().or(z.literal('')),
    }),
);

const form = useForm({
    validationSchema: formSchema,
});

const openModal = () => {
    state.isOpen = true;
};

const onSubmit = form.handleSubmit(async (values): Promise<void> => {
    state.isLoading = true;
    try {
        await submitUserMessageAction(
            values.messageText,
            String(values.emailAddress).length > 0 ? values.emailAddress : undefined,
        );
    } catch (error) {
        await handleFormError(form, error, false);
    }
    state.isLoading = false;
    state.isOpen = false;
});
</script>

<template>
    <Dialog v-model:open="state.isOpen">
        <DialogTrigger as-child>
            <slot :open-modal="openModal" />
        </DialogTrigger>
        <DialogContent>
            <form @submit="onSubmit">
                <DialogHeader class="mb-4">
                    <DialogTitle>{{ title }}</DialogTitle>
                </DialogHeader>

                <div v-if="form.errorBag.value._">
                    <ul class="mb-4">
                        <li
                            v-for="(error, index) in form.errorBag.value._"
                            :key="index"
                            class="text-sm font-medium text-destructive"
                        >
                            {{ error }}
                        </li>
                    </ul>
                </div>

                <FormField
                    v-slot="{ componentField, errors }"
                    name="messageText"
                    :validate-on-input="false"
                    :validate-on-model-update="false"
                    :validate-on-blur="false"
                >
                    <FormItem class="mb-4">
                        <FormLabel>Message</FormLabel>
                        <FormControl>
                            <Textarea
                                type="text"
                                v-bind="componentField"
                                :disabled="state.isLoading"
                            />
                        </FormControl>
                        <FormMessage v-if="errors">
                            {{ errors }}
                        </FormMessage>
                    </FormItem>
                </FormField>

                <FormField
                    v-slot="{ componentField, errors }"
                    name="emailAddress"
                    :validate-on-input="false"
                    :validate-on-model-update="false"
                    :validate-on-blur="false"
                >
                    <FormItem class="mb-4">
                        <FormLabel>Email address (optional)</FormLabel>
                        <FormControl>
                            <Input
                                type="email"
                                v-bind="componentField"
                                :disabled="state.isLoading"
                            />
                        </FormControl>
                        <FormDescription class="text-xs">
                            If you would like to receive a response, please add your email address.
                            We do not store your username or any other user data.
                        </FormDescription>
                        <FormMessage v-if="errors">
                            {{ errors }}
                        </FormMessage>
                    </FormItem>
                </FormField>

                <DialogFooter>
                    <Button
                        type="submit"
                        :disabled="state.isLoading"
                        class="flex w-20 items-center justify-center"
                    >
                        <span v-if="!state.isLoading">Submit</span>
                        <Loader v-else :width="18" :height="18" />
                    </Button>
                </DialogFooter>
            </form>
        </DialogContent>
    </Dialog>
</template>
