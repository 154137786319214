// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Leetcode Wizard
 * Leetcode Wizard API Description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TokenDataResponseDto } from './TokenDataResponseDto';
import {
    TokenDataResponseDtoFromJSON,
    TokenDataResponseDtoFromJSONTyped,
    TokenDataResponseDtoToJSON,
} from './TokenDataResponseDto';
import type { UserDataResponseDto } from './UserDataResponseDto';
import {
    UserDataResponseDtoFromJSON,
    UserDataResponseDtoFromJSONTyped,
    UserDataResponseDtoToJSON,
} from './UserDataResponseDto';

/**
 *
 * @export
 * @interface RefreshTokenResponseDto
 */
export interface RefreshTokenResponseDto {
    /**
     *
     * @type {UserDataResponseDto}
     * @memberof RefreshTokenResponseDto
     */
    userData: UserDataResponseDto;
    /**
     *
     * @type {TokenDataResponseDto}
     * @memberof RefreshTokenResponseDto
     */
    tokenData: TokenDataResponseDto;
}

/**
 * Check if a given object implements the RefreshTokenResponseDto interface.
 */
export function instanceOfRefreshTokenResponseDto(value: object): value is RefreshTokenResponseDto {
    if (!('userData' in value) || value['userData'] === undefined) return false;
    if (!('tokenData' in value) || value['tokenData'] === undefined) return false;
    return true;
}

export function RefreshTokenResponseDtoFromJSON(json: any): RefreshTokenResponseDto {
    return RefreshTokenResponseDtoFromJSONTyped(json, false);
}

export function RefreshTokenResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): RefreshTokenResponseDto {
    if (json == null) {
        return json;
    }
    return {
        userData: UserDataResponseDtoFromJSON(json['userData']),
        tokenData: TokenDataResponseDtoFromJSON(json['tokenData']),
    };
}

export function RefreshTokenResponseDtoToJSON(value?: RefreshTokenResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        userData: UserDataResponseDtoToJSON(value['userData']),
        tokenData: TokenDataResponseDtoToJSON(value['tokenData']),
    };
}
