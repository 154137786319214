import { WebSocketService } from '../../ws/WebSocketService.ts';
import { WS_REQUEST } from '../../ws/WebSocketEvents.ts';
import { STATIC_NAMESPACE_ID } from '../../constants/StaticNamespaceId.ts';
import { ClientStoreSyncData } from '../../types/sync/ClientStoreSyncData.ts';
import { useUiStore } from '../../stores/UiStore.ts';

export const syncSetClientStoreAction = (storeData: ClientStoreSyncData): void => {
    const uiStore = useUiStore();
    if (uiStore.isInsideWebView) {
        throw new Error('syncSetClientStoreAction should not be called from inside the web view');
    }

    WebSocketService.instance.send(WS_REQUEST.sync_setClientStore, {
        storeData: storeData,
        id: STATIC_NAMESPACE_ID,
    });
};
