// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Leetcode Wizard
 * Leetcode Wizard API Description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface GetAnalyticsAbTestGroupResponseDto
 */
export interface GetAnalyticsAbTestGroupResponseDto {
    /**
     *
     * @type {number}
     * @memberof GetAnalyticsAbTestGroupResponseDto
     */
    g: number;
}

/**
 * Check if a given object implements the GetAnalyticsAbTestGroupResponseDto interface.
 */
export function instanceOfGetAnalyticsAbTestGroupResponseDto(
    value: object,
): value is GetAnalyticsAbTestGroupResponseDto {
    if (!('g' in value) || value['g'] === undefined) return false;
    return true;
}

export function GetAnalyticsAbTestGroupResponseDtoFromJSON(
    json: any,
): GetAnalyticsAbTestGroupResponseDto {
    return GetAnalyticsAbTestGroupResponseDtoFromJSONTyped(json, false);
}

export function GetAnalyticsAbTestGroupResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): GetAnalyticsAbTestGroupResponseDto {
    if (json == null) {
        return json;
    }
    return {
        g: json['g'],
    };
}

export function GetAnalyticsAbTestGroupResponseDtoToJSON(
    value?: GetAnalyticsAbTestGroupResponseDto | null,
): any {
    if (value == null) {
        return value;
    }
    return {
        g: value['g'],
    };
}
