import { useHostStore } from '../../../stores/HostStore.ts';
import { useSettingsStore } from '../../../stores/SettingsStore.ts';
import { AuthenticationService } from '../../../services/AuthenticationService.ts';
import { createTextToast } from '../../../utils/ToastUtil.ts';
import { truncateString } from '../../../utils/AppUtil.ts';

export const handleRequestSourcesIdListResponseAction = async (sourceIds: string[]) => {
    const hostStore = useHostStore();
    const settingStore = useSettingsStore();

    // Ignore response if not logged in or still onboarding
    if (!AuthenticationService.instance.isLoggedIn() || settingStore.isOnboarding) {
        return;
    }

    // If source gets lost
    if (hostStore.selectedSourceId && hostStore.selectedSourceName) {
        if (sourceIds.includes(hostStore.selectedSourceId)) {
            return;
        }

        createTextToast(
            `Source (${truncateString(hostStore.selectedSourceName, 15)}) has been lost.`,
            'Make sure the window is not minimized.',
            'destructive',
            true,
        );

        hostStore.lostSourceId = hostStore.selectedSourceId;
        hostStore.lostSourceName = hostStore.selectedSourceName;

        hostStore.selectedSourceId = null;
        hostStore.selectedSourceName = null;
    }

    if (hostStore.lostSourceId && hostStore.lostSourceName) {
        if (!sourceIds.includes(hostStore.lostSourceId)) {
            return;
        }

        createTextToast(
            `Source (${truncateString(hostStore.lostSourceName, 15)}) has been recovered.`,
            undefined,
            'success',
            true,
        );

        hostStore.selectedSourceId = hostStore.lostSourceId;
        hostStore.selectedSourceName = hostStore.lostSourceName;

        hostStore.lostSourceId = null;
        hostStore.lostSourceName = null;
    }
};
