// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Leetcode Wizard
 * Leetcode Wizard API Description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AnalyticsDashboardUsageLogStatsResponseDto
 */
export interface AnalyticsDashboardUsageLogStatsResponseDto {
    /**
     *
     * @type {Array<string>}
     * @memberof AnalyticsDashboardUsageLogStatsResponseDto
     */
    dates: Array<string>;
    /**
     *
     * @type {Array<number>}
     * @memberof AnalyticsDashboardUsageLogStatsResponseDto
     */
    costs: Array<number>;
    /**
     *
     * @type {Array<object>}
     * @memberof AnalyticsDashboardUsageLogStatsResponseDto
     */
    modelUses: Array<object>;
}

/**
 * Check if a given object implements the AnalyticsDashboardUsageLogStatsResponseDto interface.
 */
export function instanceOfAnalyticsDashboardUsageLogStatsResponseDto(
    value: object,
): value is AnalyticsDashboardUsageLogStatsResponseDto {
    if (!('dates' in value) || value['dates'] === undefined) return false;
    if (!('costs' in value) || value['costs'] === undefined) return false;
    if (!('modelUses' in value) || value['modelUses'] === undefined) return false;
    return true;
}

export function AnalyticsDashboardUsageLogStatsResponseDtoFromJSON(
    json: any,
): AnalyticsDashboardUsageLogStatsResponseDto {
    return AnalyticsDashboardUsageLogStatsResponseDtoFromJSONTyped(json, false);
}

export function AnalyticsDashboardUsageLogStatsResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): AnalyticsDashboardUsageLogStatsResponseDto {
    if (json == null) {
        return json;
    }
    return {
        dates: json['dates'],
        costs: json['costs'],
        modelUses: json['modelUses'],
    };
}

export function AnalyticsDashboardUsageLogStatsResponseDtoToJSON(
    value?: AnalyticsDashboardUsageLogStatsResponseDto | null,
): any {
    if (value == null) {
        return value;
    }
    return {
        dates: value['dates'],
        costs: value['costs'],
        modelUses: value['modelUses'],
    };
}
