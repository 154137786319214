import { useUiStore } from '../../stores/UiStore.ts';
import { createTextToast } from '../../utils/ToastUtil.ts';
import { apiService } from '../../services/ApiService.ts';
import { CreateCheckoutSessionResponseDto } from '../../../@generated/api-client';

export const startSubscriptionProcessAction = async () => {
    const uiStore = useUiStore();
    uiStore.isFullLoading = true;

    let apiResponse: CreateCheckoutSessionResponseDto;
    try {
        apiResponse = await apiService.getApi().stripeCreateCheckoutSessionV1();
    } catch {
        uiStore.isFullLoading = false;

        createTextToast(
            'Failed to connect to payment provider',
            'Please try again later',
            'destructive',
            false,
        );

        return;
    }

    uiStore.isFullLoading = false;

    window.open(apiResponse.url, '_blank');
};
