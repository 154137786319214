// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Leetcode Wizard
 * Leetcode Wizard API Description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TokenDataResponseDto } from './TokenDataResponseDto';
import {
    TokenDataResponseDtoFromJSON,
    TokenDataResponseDtoFromJSONTyped,
    TokenDataResponseDtoToJSON,
} from './TokenDataResponseDto';
import type { UserDataResponseDto } from './UserDataResponseDto';
import {
    UserDataResponseDtoFromJSON,
    UserDataResponseDtoFromJSONTyped,
    UserDataResponseDtoToJSON,
} from './UserDataResponseDto';

/**
 *
 * @export
 * @interface LoginTransferTokenResponseDto
 */
export interface LoginTransferTokenResponseDto {
    /**
     *
     * @type {UserDataResponseDto}
     * @memberof LoginTransferTokenResponseDto
     */
    userData: UserDataResponseDto;
    /**
     *
     * @type {TokenDataResponseDto}
     * @memberof LoginTransferTokenResponseDto
     */
    tokenData: TokenDataResponseDto;
    /**
     *
     * @type {string}
     * @memberof LoginTransferTokenResponseDto
     */
    redirectUrl: string;
}

/**
 * Check if a given object implements the LoginTransferTokenResponseDto interface.
 */
export function instanceOfLoginTransferTokenResponseDto(
    value: object,
): value is LoginTransferTokenResponseDto {
    if (!('userData' in value) || value['userData'] === undefined) return false;
    if (!('tokenData' in value) || value['tokenData'] === undefined) return false;
    if (!('redirectUrl' in value) || value['redirectUrl'] === undefined) return false;
    return true;
}

export function LoginTransferTokenResponseDtoFromJSON(json: any): LoginTransferTokenResponseDto {
    return LoginTransferTokenResponseDtoFromJSONTyped(json, false);
}

export function LoginTransferTokenResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): LoginTransferTokenResponseDto {
    if (json == null) {
        return json;
    }
    return {
        userData: UserDataResponseDtoFromJSON(json['userData']),
        tokenData: TokenDataResponseDtoFromJSON(json['tokenData']),
        redirectUrl: json['redirectUrl'],
    };
}

export function LoginTransferTokenResponseDtoToJSON(
    value?: LoginTransferTokenResponseDto | null,
): any {
    if (value == null) {
        return value;
    }
    return {
        userData: UserDataResponseDtoToJSON(value['userData']),
        tokenData: TokenDataResponseDtoToJSON(value['tokenData']),
        redirectUrl: value['redirectUrl'],
    };
}
