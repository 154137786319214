import { useUiStore } from '../../stores/UiStore.ts';

export const ToggleTaskbarIconVisibilityAction = async () => {
    if (!window.electronApi) {
        return;
    }

    const uiStore = useUiStore();
    const newState = !uiStore.isTaskbarIconVisible;

    uiStore.isTaskbarIconVisible = newState;

    if (window.electronApi.setTaskbarIconVisibility !== undefined) {
        window.electronApi.setTaskbarIconVisibility(newState);
    }
};
