<script setup lang="ts">
import { useSettingsStore } from '../../stores/SettingsStore.ts';
import { setAppAlwaysOnTopAction } from '../../actions/electron/SetAppAlwaysOnTopAction.ts';
import { minimizeAppAction } from '../../actions/electron/MinimizeAppAction.ts';
import { quitAppAction } from '../../actions/electron/QuitAppAction.ts';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../ui/tooltip';
import { Button } from '../ui/button';
import { Minus, X, Pin } from 'lucide-vue-next';

const settingsStore = useSettingsStore();

const setAlwaysOnTop = async () => {
    await setAppAlwaysOnTopAction(!settingsStore.appAlwaysOnTop);
};

const minimizeApp = async () => {
    await minimizeAppAction();
};

const quitApp = async () => {
    await quitAppAction();
};
</script>

<template>
    <div class="flex h-8 items-center justify-end rounded-t border-b bg-card">
        <div class="draggable h-full w-full" />
        <div class="flex items-center">
            <!-- Pin button -->
            <TooltipProvider :delay-duration="200">
                <Tooltip>
                    <TooltipTrigger as-child>
                        <Button
                            variant="outline"
                            size="icon"
                            class="h-8 w-8 rounded-none border-0 bg-card shadow-none"
                            :class="{
                                'text-gray-400 dark:text-gray-800': !settingsStore.appAlwaysOnTop,
                            }"
                            @click="setAlwaysOnTop"
                        >
                            <Pin class="h-4 w-4" />
                        </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                        <p>Pin app on top</p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>

            <!-- Minimize button -->
            <Button
                variant="outline"
                size="icon"
                class="h-8 w-8 rounded-none border-0 bg-card shadow-none"
                @click="minimizeApp"
            >
                <Minus class="h-4 w-4" />
            </Button>

            <!-- Quit button -->
            <Button
                variant="outline"
                size="icon"
                class="h-8 w-8 rounded-none rounded-tr border-0 bg-card shadow-none"
                @click="quitApp"
            >
                <X class="h-4 w-4" />
            </Button>
        </div>
    </div>
</template>
