import { useUiStore } from '../../stores/UiStore.ts';
import { eventEmitterUtil } from '../../utils/EventEmitterUtil.ts';
import { createTextToast } from '../../utils/ToastUtil.ts';

export const toggleContentProtectionAction = async (showWarningModal: boolean) => {
    if (!window.electronApi) {
        return;
    }

    const uiStore = useUiStore();
    const newState = !uiStore.isContentProtectionEnabled;

    if (!newState && showWarningModal) {
        eventEmitterUtil.emit('showContentProtectionWarning');

        return;
    }

    uiStore.isContentProtectionEnabled = newState;

    window.electronApi.setContentProtection(newState);

    if (newState) {
        createTextToast(
            `Content protection has been enabled,`,
            'The desktop app is now fully hidden.',
            'default',
            true,
        );
    } else {
        createTextToast(
            `Content protection has been disabled.`,
            'The desktop app is now fully visible to all other software, be careful.',
            'destructive',
            true,
        );
    }
};
