<script setup lang="ts">
import { Card, CardContent } from '../ui/card';
import { useUiStore } from '../../stores/UiStore.ts';
import { useWebSocketStore } from '../../stores/WebSocketStore.ts';

const uiStore = useUiStore();
const webSocketStore = useWebSocketStore();
</script>

<template>
    <Card
        class="select-none whitespace-nowrap rounded-none border-b border-t-0 shadow-2xl dark:border-gray-800 dark:bg-gray-900"
    >
        <CardContent class="flex h-full items-center justify-end px-2 py-0">
            <template v-if="!uiStore.isInsideWebView">
                <span
                    v-if="
                        !webSocketStore.isConnected &&
                        webSocketStore.connectedClients.find((client) => !client.isDesktopApp)
                    "
                    class="connection-dot__wrapper text-red-500"
                >
                    Disconnected
                    <span class="connection-dot connection-dot--disconnected" />
                </span>
                <span
                    v-else-if="
                        webSocketStore.connectedClients.find((client) => !client.isDesktopApp)
                    "
                    class="connection-dot__wrapper text-green-500"
                >
                    Connected to web view
                    <span class="connection-dot connection-dot--connected" />
                </span>
                <span v-else class="connection-dot__wrapper text-yellow-600">
                    Waiting for web view...
                    <span class="connection-dot connection-dot--waiting" />
                </span>
            </template>
            <template v-else>
                <span
                    v-if="!webSocketStore.isConnected"
                    class="connection-dot__wrapper text-red-500"
                >
                    Disconnected from desktop app
                    <span class="connection-dot connection-dot--disconnected" />
                </span>
                <span
                    v-else-if="
                        webSocketStore.isConnected &&
                        !webSocketStore.connectedClients.find((client) => client.isDesktopApp)
                    "
                    class="connection-dot__wrapper text-yellow-600"
                >
                    Waiting for desktop app...
                    <span class="connection-dot connection-dot--waiting" />
                </span>
                <span
                    v-else-if="
                        webSocketStore.isConnected &&
                        webSocketStore.connectedClients.find((client) => client.isDesktopApp)
                    "
                    class="connection-dot__wrapper text-green-500"
                >
                    Connected to desktop app
                    <span class="connection-dot connection-dot--connected" />
                </span>
            </template>
        </CardContent>
    </Card>
</template>

<style scoped>
@keyframes pulse-connected {
    0%,
    100% {
        background-color: rgb(21 128 6);
    }
    50% {
        background-color: rgb(74 222 128);
    }
}

@keyframes pulse-disconnected {
    0%,
    100% {
        background-color: rgb(185 28 28);
    }
    50% {
        background-color: rgb(248 113 113);
    }
}

@keyframes pulse-waiting {
    0%,
    100% {
        background-color: rgb(161 98 7);
    }
    50% {
        background-color: rgb(250 204 21);
    }
}

.connection-dot {
    @apply ms-1.5 h-3.5 w-3.5;
    border-radius: 50%;
    display: inline-block;
}

.connection-dot__wrapper {
    @apply m-0 flex items-center text-sm;
}

.connection-dot.connection-dot--connected {
    animation: pulse-connected 2s infinite;
}

.connection-dot.connection-dot--disconnected {
    animation: pulse-disconnected 2s infinite;
}

.connection-dot.connection-dot--waiting {
    animation: pulse-waiting 2s infinite;
}
</style>
