import type { SubmissionContext } from 'vee-validate';
import { createTextToast } from './ToastUtil.ts';
import { ResponseError } from '../../@generated/api-client';

export const handleFormError = async (
    form: SubmissionContext,
    error: ResponseError | unknown,
    pushToWebView: boolean,
    unknownErrorMessage = `An unknown error occurred. Please try again.`,
): Promise<void> => {
    if (!(error instanceof ResponseError)) {
        createTextToast(unknownErrorMessage, undefined, 'destructive', pushToWebView);

        return;
    }

    const errorData = await error.response.json();

    if (errorData.message === undefined) {
        createTextToast(unknownErrorMessage, undefined, 'destructive', pushToWebView);

        return;
    }

    let errorMessage = errorData.message;
    if (Array.isArray(errorMessage)) {
        errorMessage = errorMessage[0];
    }

    form.setErrors({
        _: errorMessage,
    });
};

export const handleApiError = async (
    error: ResponseError | unknown,
    unknownErrorMessage = `An unknown error occurred. Please try again.`,
    pushToWebView: boolean,
): Promise<void> => {
    console.error(error);

    if (!(error instanceof ResponseError)) {
        createTextToast(unknownErrorMessage, undefined, 'destructive', pushToWebView);

        return;
    }

    const errorData = await error.response.json();

    if (errorData.message === undefined) {
        createTextToast(unknownErrorMessage, undefined, 'destructive', pushToWebView);

        return;
    }

    let errorMessage = errorData.message;
    if (Array.isArray(errorMessage)) {
        errorMessage = errorMessage[0];
    }

    createTextToast(errorMessage, undefined, 'destructive', pushToWebView);
};
