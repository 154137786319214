import { WebSocketService } from '../../ws/WebSocketService.ts';
import { WS_REQUEST } from '../../ws/WebSocketEvents.ts';
import { STATIC_NAMESPACE_ID } from '../../constants/StaticNamespaceId.ts';
import { useUiStore } from '../../stores/UiStore.ts';
import { SolveAlgorithm } from '../../types/solve/SolveAlgorithm.ts';

export const syncSetSolveAlgorithms = (solveAlgorithms: {
    [key: string]: SolveAlgorithm;
}): void => {
    const uiStore = useUiStore();
    if (uiStore.isInsideWebView) {
        throw new Error('syncSetSolveAlgorithms should not be called from inside the web view');
    }

    WebSocketService.instance.send(WS_REQUEST.sync_setSetSolveAlgorithms, {
        solveAlgorithms: solveAlgorithms,
        id: STATIC_NAMESPACE_ID,
    });
};
