<script setup lang="ts">
import ContentProtectionWarningDialog from './components/dialogs/ContentProtectionWarningDialog.vue';
import TrialInputWarningDialog from './components/dialogs/TrialInputWarningDialog.vue';
import ModelSelectionDialog from './components/dialogs/ModelSelectionDialog.vue';
import Toolbar from './components/toolbar/Toolbar.vue';
import FirstTimeHideWarningDialog from './components/dialogs/FirstTimeHideWarningDialog.vue';
import { Toaster } from './components/ui/toast';
import FullLoader from './components/fullLoader/FullLoader.vue';
import ConnectionBar from './components/connectionBar/ConnectionBar.vue';
import HotkeyConfigurationDialog from './components/dialogs/hotkey-configuration/HotkeyConfigurationDialog.vue';
import Footer from './components/footer/Footer.vue';
import SolvePreview from './components/solvePreview/SolvePreview.vue';
import Framebar from './components/framebar/Framebar.vue';
import Navbar from './components/navbar/Navbar.vue';
import { useRoute } from 'vue-router';
import { useUiStore } from './stores/UiStore.ts';
import { useSettingsStore } from './stores/SettingsStore.ts';
import { useWebSocketStore } from './stores/WebSocketStore.ts';
import { computed } from 'vue';

const route = useRoute();

const uiStore = useUiStore();
const settingsStore = useSettingsStore();
const webSocketStore = useWebSocketStore();

const showTopBars = computed(() => {
    return !route.path.startsWith('/auth');
});
</script>

<template>
    <main class="flex h-screen flex-col bg-white dark:bg-gray-900">
        <Framebar class="h-[34px] flex-shrink-0" v-if="!uiStore.isInsideWebView" />
        <FullLoader />
        <template v-if="showTopBars">
            <Toolbar class="h-[34px] flex-shrink-0" />
            <Navbar class="h-[34px] flex-shrink-0" v-if="!settingsStore.isOnboarding" />
            <ConnectionBar
                class="h-[34px] flex-shrink-0"
                v-if="
                    !settingsStore.isOnboarding &&
                    (uiStore.isInsideWebView ||
                        (!uiStore.isInsideWebView && webSocketStore.connectedClients.length > 1))
                "
            />
        </template>

        <!-- Dialogs -->
        <template v-if="!uiStore.isInsideWebView">
            <TrialInputWarningDialog />
            <FirstTimeHideWarningDialog />
            <ContentProtectionWarningDialog />
            <HotkeyConfigurationDialog />
            <ModelSelectionDialog />
        </template>

        <SolvePreview />
        <RouterView />

        <Footer class="h-[38px] flex-shrink-0" />
        <Toaster />
    </main>
</template>
