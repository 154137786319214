import { WebSocketMessageData } from '../../../types/ws/WebSocketMessageData.ts';
import { useUiStore } from '../../../stores/UiStore.ts';
import { eventEmitterUtil } from '../../../utils/EventEmitterUtil.ts';

export const syncShowSolvePreviewMessageHandler = (messageData: WebSocketMessageData) => {
    const uiStore = useUiStore();
    if (!uiStore.isInsideWebView) {
        return;
    }

    const imageData = messageData.imageData;
    if (imageData === undefined) {
        return;
    }

    eventEmitterUtil.emit('showSolvePreview', imageData);
};
