import { useHostStore } from '../../../stores/HostStore.ts';
import { InputSource } from '../../../types/electron/InputSource.ts';
import { useSettingsStore } from '../../../stores/SettingsStore.ts';
import { AuthenticationService } from '../../../services/AuthenticationService.ts';
import { setInputSourceAction } from '../../solve/SetInputSourceAction.ts';
import { createTextToast } from '../../../utils/ToastUtil.ts';
import { isSourceAutoDetected } from '../../../utils/AutoDetectUtil.ts';

export const handleRequestSourcesResponseAction = async (sources: InputSource[]) => {
    const hostStore = useHostStore();
    hostStore.sources = sources;
    hostStore.sourcesLoading = false;

    const settingStore = useSettingsStore();

    // Ignore response if not logged in or still onboarding
    if (
        hostStore.selectedSourceId ||
        !AuthenticationService.instance.isLoggedIn() ||
        settingStore.isOnboarding
    ) {
        return;
    }

    // Try to automatically assign a valid source
    for (const source of sources) {
        const autoDetected = isSourceAutoDetected(source.name);

        if (autoDetected) {
            void setInputSourceAction(source);

            createTextToast(`Input source automatically detected.`, source.name, 'success', true);

            return;
        }
    }
};
