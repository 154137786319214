<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import MarkdownIt from 'markdown-it';

const md = MarkdownIt({
    html: true,
    linkify: false,
    typographer: true,
});

const props = defineProps<{
    content: string;
}>();

const markdownOutputElement = ref<HTMLDivElement | null>(null);

const formattedContent = ref('');

const formatMarkdown = () => {
    if (markdownOutputElement.value) {
        // const x = "```markdown\\n- Test Case 1:\\n  ```python\\n  nums = [2, 7, 11, 15]\\n  target = 9\\n  # Expected output: [0, 1]\\n  ```\\n\\n- Test Case 2:\\n  ```python\\n  nums = [3, 2, 4]\\n  target = 6\\n  # Expected output: [1, 2]\\n  ```\\n\\n- Test Case 3:\\n  ```python\\n  nums = [3, 3]\\n  target = 6\\n  # Expected output: [0, 1]\\n  ```\\n\\n- Test Case 4:\\n  ```python\\n  nums = [1, 2, 3, 4, 5]\\n  target = 10\\n  # Expected output: [] (no solution)\\n  ```\\n\\n- Test Case 5:\\n  ```python\\n  nums = []\\n  target = 5\\n  # Expected output: [] (empty list)\\n  ```\\n\\n- Test Case 6:\\n  ```python\\n  nums = [-1, -2, -3, -4, -5]\\n  target = -8\\n  # Expected output: [2, 4]\\n  ```\\n\\n- Test Case 7:\\n  ```python\\n  nums = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]\\n  target = 19\\n  # Expected output: [8, 9]\\n  ```\\n```";

        const cleanedContent = props.content
            .replace(/```/g, '')
            .replace(/markdown/g, '')
            .trim();

        formattedContent.value = md.render(cleanedContent);
    }
};

watch(
    () => [props.content],
    async () => {
        formatMarkdown();
    },
);

onMounted(() => {
    formatMarkdown();
});
</script>

<template>
    <div ref="markdownOutputElement" class="markdown-output" v-html="formattedContent"></div>
</template>

<style>
.markdown-output {
    @apply prose prose-sm prose-slate bg-card p-4 dark:prose-invert;
    font-size: 0.9rem;
}

.markdown-output > *:first-child,
.markdown-output ul li:first-child p {
    margin-top: 0 !important;
}

.markdown-output > *:last-child,
.markdown-output ul li:last-child p {
    margin-bottom: 0 !important;
}

.markdown-output ul li p {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}
</style>
