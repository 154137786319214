import { useHostStore } from '../../stores/HostStore.ts';

export const requestSourcesAction = () => {
    if (!window.electronApi) {
        return;
    }

    const hostStore = useHostStore();
    hostStore.sourcesLoading = true;

    window.electronApi.requestSources();
};
