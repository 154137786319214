// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Leetcode Wizard
 * Leetcode Wizard API Description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SolveAlgorithmsRequestDto
 */
export interface SolveAlgorithmsRequestDto {
    /**
     *
     * @type {string}
     * @memberof SolveAlgorithmsRequestDto
     */
    imageData: string;
    /**
     *
     * @type {string}
     * @memberof SolveAlgorithmsRequestDto
     */
    problemExtract: string;
    /**
     *
     * @type {string}
     * @memberof SolveAlgorithmsRequestDto
     */
    exampleCodeExtract: string;
    /**
     *
     * @type {string}
     * @memberof SolveAlgorithmsRequestDto
     */
    solveId: string;
    /**
     *
     * @type {string}
     * @memberof SolveAlgorithmsRequestDto
     */
    streamId: string;
    /**
     *
     * @type {string}
     * @memberof SolveAlgorithmsRequestDto
     */
    aiModel: SolveAlgorithmsRequestDtoAiModelEnum;
}

/**
 * @export
 */
export const SolveAlgorithmsRequestDtoAiModelEnum = {
    Gpt4o: 'gpt-4o',
    Claude35Sonnet: 'claude-35-sonnet',
    O1Mini: 'o1-mini',
} as const;
export type SolveAlgorithmsRequestDtoAiModelEnum =
    (typeof SolveAlgorithmsRequestDtoAiModelEnum)[keyof typeof SolveAlgorithmsRequestDtoAiModelEnum];

/**
 * Check if a given object implements the SolveAlgorithmsRequestDto interface.
 */
export function instanceOfSolveAlgorithmsRequestDto(
    value: object,
): value is SolveAlgorithmsRequestDto {
    if (!('imageData' in value) || value['imageData'] === undefined) return false;
    if (!('problemExtract' in value) || value['problemExtract'] === undefined) return false;
    if (!('exampleCodeExtract' in value) || value['exampleCodeExtract'] === undefined) return false;
    if (!('solveId' in value) || value['solveId'] === undefined) return false;
    if (!('streamId' in value) || value['streamId'] === undefined) return false;
    if (!('aiModel' in value) || value['aiModel'] === undefined) return false;
    return true;
}

export function SolveAlgorithmsRequestDtoFromJSON(json: any): SolveAlgorithmsRequestDto {
    return SolveAlgorithmsRequestDtoFromJSONTyped(json, false);
}

export function SolveAlgorithmsRequestDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): SolveAlgorithmsRequestDto {
    if (json == null) {
        return json;
    }
    return {
        imageData: json['imageData'],
        problemExtract: json['problemExtract'],
        exampleCodeExtract: json['exampleCodeExtract'],
        solveId: json['solveId'],
        streamId: json['streamId'],
        aiModel: json['aiModel'],
    };
}

export function SolveAlgorithmsRequestDtoToJSON(value?: SolveAlgorithmsRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        imageData: value['imageData'],
        problemExtract: value['problemExtract'],
        exampleCodeExtract: value['exampleCodeExtract'],
        solveId: value['solveId'],
        streamId: value['streamId'],
        aiModel: value['aiModel'],
    };
}
