<script setup lang="ts">
import Loader from '../Loader.vue';
import { useUiStore } from '../../stores/UiStore.ts';

const uiStore = useUiStore();
</script>

<template>
    <div
        v-if="uiStore.isFullLoading"
        class="absolute z-50 flex h-full w-full select-none items-center justify-center bg-gray-700 opacity-50"
    >
        <Loader :width="62" :height="62" />
    </div>
</template>
