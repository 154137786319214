// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Leetcode Wizard
 * Leetcode Wizard API Description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AnalyticsDashboardSessionOriginsResponseDto
 */
export interface AnalyticsDashboardSessionOriginsResponseDto {
    /**
     *
     * @type {Array<string>}
     * @memberof AnalyticsDashboardSessionOriginsResponseDto
     */
    dates: Array<string>;
    /**
     *
     * @type {Array<object>}
     * @memberof AnalyticsDashboardSessionOriginsResponseDto
     */
    origins: Array<object>;
}

/**
 * Check if a given object implements the AnalyticsDashboardSessionOriginsResponseDto interface.
 */
export function instanceOfAnalyticsDashboardSessionOriginsResponseDto(
    value: object,
): value is AnalyticsDashboardSessionOriginsResponseDto {
    if (!('dates' in value) || value['dates'] === undefined) return false;
    if (!('origins' in value) || value['origins'] === undefined) return false;
    return true;
}

export function AnalyticsDashboardSessionOriginsResponseDtoFromJSON(
    json: any,
): AnalyticsDashboardSessionOriginsResponseDto {
    return AnalyticsDashboardSessionOriginsResponseDtoFromJSONTyped(json, false);
}

export function AnalyticsDashboardSessionOriginsResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): AnalyticsDashboardSessionOriginsResponseDto {
    if (json == null) {
        return json;
    }
    return {
        dates: json['dates'],
        origins: json['origins'],
    };
}

export function AnalyticsDashboardSessionOriginsResponseDtoToJSON(
    value?: AnalyticsDashboardSessionOriginsResponseDto | null,
): any {
    if (value == null) {
        return value;
    }
    return {
        dates: value['dates'],
        origins: value['origins'],
    };
}
