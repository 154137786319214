import { WebSocketService } from '../../ws/WebSocketService.ts';
import { WS_REQUEST } from '../../ws/WebSocketEvents.ts';
import { STATIC_NAMESPACE_ID } from '../../constants/StaticNamespaceId.ts';
import { useUiStore } from '../../stores/UiStore.ts';

export const syncSetRouteAction = (route: string): void => {
    const uiStore = useUiStore();
    if (uiStore.isInsideWebView) {
        throw new Error('syncSetRouteAction should not be called from inside the web view');
    }

    WebSocketService.instance.send(WS_REQUEST.sync_setRoute, {
        route: route,
        id: STATIC_NAMESPACE_ID,
    });
};
