import { WebSocketMessageData } from '../../../types/ws/WebSocketMessageData.ts';
import { useUiStore } from '../../../stores/UiStore.ts';
import { eventEmitterUtil } from '../../../utils/EventEmitterUtil.ts';

export const syncHotkeyMessageHandler = (messageData: WebSocketMessageData) => {
    const uiStore = useUiStore();
    if (!uiStore.isInsideWebView) {
        return;
    }

    const hotkey = messageData.hotkey;
    if (hotkey === undefined) {
        return;
    }

    eventEmitterUtil.emit('hotkeyPress', hotkey);
};
