// Note: Should be kept in sync with desktop-client

export const enum WS_REQUEST {
    // Auth
    auth_logIn = 'auth/logIn',
    // Sync
    sync_hotkey = 'sync/hotkey',
    sync_pushToast = 'sync/pushToast',
    sync_setRoute = 'sync/setRoute',
    sync_setClientStore = 'sync/setClientStore',
    sync_setSettingsStore = 'sync/setSettingsStore',
    sync_setSetSolveAlgorithms = 'sync/setSolveAlgorithms',
}

export const enum WS_RESPONSE {
    // Generic
    generic = 'response:generic',
    rateLimited = 'response:rateLimited',
    connectedClients = 'response:connectedClients',
    // Auth
    auth_logIn = 'response:auth/logIn',
    // Solve
    solve_requestAlgorithms = 'response:solve/requestAlgorithms',
    solve_requestCode = 'response:solve/requestCode',
    solve_requestTests = 'response:solve/requestTests',
    solve_requestComplexity = 'response:solve/requestComplexity',
    solve_requestExtract = 'response:solve/requestExtract',
    // Sync
    sync_hotkey = 'response:sync/hotkey',
    sync_pushToast = 'response:sync/pushToast',
    sync_setRoute = 'response:sync/setRoute',
    sync_setClientStore = 'response:sync/setClientStore',
    sync_setSettingsStore = 'response:sync/setSettingsStore',
    sync_setSetSolveAlgorithms = 'response:sync/setSolveAlgorithms',
    sync_showSolvePreview = 'response:sync/showSolvePreview',
}
