import { WebSocketMessageData } from '../../../types/ws/WebSocketMessageData.ts';
import { useUiStore } from '../../../stores/UiStore.ts';
import { useClientStore } from '../../../stores/ClientStore.ts';

export const syncSetSolveAlgorithmsMessageHandler = (messageData: WebSocketMessageData) => {
    const uiStore = useUiStore();
    if (!uiStore.isInsideWebView) {
        return;
    }

    const clientStore = useClientStore();
    clientStore.solveAlgorithms = messageData?.solveAlgorithms;
};
