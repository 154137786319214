import { useUserInfoStore } from '../../stores/UserInfoStore.ts';
import { apiService } from '../../services/ApiService.ts';
import { SubscriptionTier } from '../../types/user/SubscriptionTier.ts';

export const retrieveUserInfoAction = async () => {
    const userInfoStore = useUserInfoStore();
    userInfoStore.userInfoLoading = true;

    let apiResponse;
    try {
        apiResponse = await apiService.getApi().userInfoV1();
    } catch (error) {
        console.error('Failed to fetch user data:', error);

        return;
    }

    userInfoStore.username = apiResponse.username;
    userInfoStore.creditsRemaining = apiResponse.creditsRemaining;
    userInfoStore.subscriptionTier = apiResponse.subscriptionTier as SubscriptionTier;

    userInfoStore.userInfoLoading = false;
};
