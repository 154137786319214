import { createTextToast } from '../../utils/ToastUtil.ts';

export const handleGlobalErrorAction = async (errorMessage: string) => {
    createTextToast(
        'A fatal error has occurred, please send a screenshot of the error down below to our support email (support@leetcodewizard.io):',
        errorMessage,
        'destructive',
        false,
    );

    throw new Error(errorMessage);
};
