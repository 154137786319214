// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Leetcode Wizard
 * Leetcode Wizard API Description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CreateAnalyticsEventRequestDto
 */
export interface CreateAnalyticsEventRequestDto {
    /**
     *
     * @type {string}
     * @memberof CreateAnalyticsEventRequestDto
     */
    t: CreateAnalyticsEventRequestDtoTEnum;
    /**
     *
     * @type {object}
     * @memberof CreateAnalyticsEventRequestDto
     */
    d: object;
}

/**
 * @export
 */
export const CreateAnalyticsEventRequestDtoTEnum = {
    _3cfcb25e3539d297aca30da87acb781c: '3cfcb25e3539d297aca30da87acb781c',
    _3d5b7614d005d37a102c459017b0e867: '3d5b7614d005d37a102c459017b0e867',
    _97fbe9bdb037ca2fc4f576b39997bd88: '97fbe9bdb037ca2fc4f576b39997bd88',
    _4aa60f4682d722a100c4d6e77529bd4a: '4aa60f4682d722a100c4d6e77529bd4a',
} as const;
export type CreateAnalyticsEventRequestDtoTEnum =
    (typeof CreateAnalyticsEventRequestDtoTEnum)[keyof typeof CreateAnalyticsEventRequestDtoTEnum];

/**
 * Check if a given object implements the CreateAnalyticsEventRequestDto interface.
 */
export function instanceOfCreateAnalyticsEventRequestDto(
    value: object,
): value is CreateAnalyticsEventRequestDto {
    if (!('t' in value) || value['t'] === undefined) return false;
    if (!('d' in value) || value['d'] === undefined) return false;
    return true;
}

export function CreateAnalyticsEventRequestDtoFromJSON(json: any): CreateAnalyticsEventRequestDto {
    return CreateAnalyticsEventRequestDtoFromJSONTyped(json, false);
}

export function CreateAnalyticsEventRequestDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): CreateAnalyticsEventRequestDto {
    if (json == null) {
        return json;
    }
    return {
        t: json['t'],
        d: json['d'],
    };
}

export function CreateAnalyticsEventRequestDtoToJSON(
    value?: CreateAnalyticsEventRequestDto | null,
): any {
    if (value == null) {
        return value;
    }
    return {
        t: value['t'],
        d: value['d'],
    };
}
