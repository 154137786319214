// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Leetcode Wizard
 * Leetcode Wizard API Description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SolveTestsRequestDto
 */
export interface SolveTestsRequestDto {
    /**
     *
     * @type {string}
     * @memberof SolveTestsRequestDto
     */
    code: string;
    /**
     *
     * @type {string}
     * @memberof SolveTestsRequestDto
     */
    solveId: string;
    /**
     *
     * @type {string}
     * @memberof SolveTestsRequestDto
     */
    streamId: string;
    /**
     *
     * @type {string}
     * @memberof SolveTestsRequestDto
     */
    aiModel: SolveTestsRequestDtoAiModelEnum;
}

/**
 * @export
 */
export const SolveTestsRequestDtoAiModelEnum = {
    Gpt4o: 'gpt-4o',
    Claude35Sonnet: 'claude-35-sonnet',
    O1Mini: 'o1-mini',
} as const;
export type SolveTestsRequestDtoAiModelEnum =
    (typeof SolveTestsRequestDtoAiModelEnum)[keyof typeof SolveTestsRequestDtoAiModelEnum];

/**
 * Check if a given object implements the SolveTestsRequestDto interface.
 */
export function instanceOfSolveTestsRequestDto(value: object): value is SolveTestsRequestDto {
    if (!('code' in value) || value['code'] === undefined) return false;
    if (!('solveId' in value) || value['solveId'] === undefined) return false;
    if (!('streamId' in value) || value['streamId'] === undefined) return false;
    if (!('aiModel' in value) || value['aiModel'] === undefined) return false;
    return true;
}

export function SolveTestsRequestDtoFromJSON(json: any): SolveTestsRequestDto {
    return SolveTestsRequestDtoFromJSONTyped(json, false);
}

export function SolveTestsRequestDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): SolveTestsRequestDto {
    if (json == null) {
        return json;
    }
    return {
        code: json['code'],
        solveId: json['solveId'],
        streamId: json['streamId'],
        aiModel: json['aiModel'],
    };
}

export function SolveTestsRequestDtoToJSON(value?: SolveTestsRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        code: value['code'],
        solveId: value['solveId'],
        streamId: value['streamId'],
        aiModel: value['aiModel'],
    };
}
