// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Leetcode Wizard
 * Leetcode Wizard API Description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface LinkAffiliateRequestDto
 */
export interface LinkAffiliateRequestDto {
    /**
     *
     * @type {string}
     * @memberof LinkAffiliateRequestDto
     */
    a: string;
}

/**
 * Check if a given object implements the LinkAffiliateRequestDto interface.
 */
export function instanceOfLinkAffiliateRequestDto(value: object): value is LinkAffiliateRequestDto {
    if (!('a' in value) || value['a'] === undefined) return false;
    return true;
}

export function LinkAffiliateRequestDtoFromJSON(json: any): LinkAffiliateRequestDto {
    return LinkAffiliateRequestDtoFromJSONTyped(json, false);
}

export function LinkAffiliateRequestDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): LinkAffiliateRequestDto {
    if (json == null) {
        return json;
    }
    return {
        a: json['a'],
    };
}

export function LinkAffiliateRequestDtoToJSON(value?: LinkAffiliateRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        a: value['a'],
    };
}
