import { useUiStore } from '../../stores/UiStore.ts';
import { useClientStore } from '../../stores/ClientStore.ts';
import { router, ROUTES } from '../../router.ts';

export const resetAction = async (navigateToStartPage: boolean) => {
    const clientStore = useClientStore();

    clientStore.$patch({
        activeSolveId: null,
        activeAlgorithmId: null,
        solveAlgorithms: {},
    });

    const uiStore = useUiStore();
    uiStore.isFullLoading = false;

    if (navigateToStartPage) {
        await router.push({ name: ROUTES.index });
    }
};
