import { router, ROUTES } from '../../router.ts';
import { useClientStore } from '../../stores/ClientStore.ts';
import { requestCodeOutputAction } from './RequestCodeOutputAction.ts';
import { generateCodeStreamId } from '../../ws/id/GenerateCodeStreamId.ts';
import { generateTestsStreamId } from '../../ws/id/GenerateTestsStreamId.ts';
import { generateComplexityStreamId } from '../../ws/id/GenerateComplexityStreamId.ts';
import { useUiStore } from '../../stores/UiStore.ts';

export const selectAlgorithmAction = async (solveId: string, algorithmId: string) => {
    const uiStore = useUiStore();
    if (uiStore.isInsideWebView) {
        console.debug(`selectAlgorithmAction called from inside web view, ignoring...`);
        return;
    }

    const clientStore = useClientStore();
    clientStore.activeAlgorithmId = algorithmId;

    const codeStreamId = generateCodeStreamId(solveId, algorithmId);

    const isCodeStreamWaiting = clientStore.streamsWaiting.includes(codeStreamId);
    const isCodeStreamInProgress = clientStore.streamsInProgress.includes(codeStreamId);
    const isCodeStreamAvailable =
        clientStore.streams[codeStreamId] !== undefined &&
        clientStore.streams[codeStreamId].length > 0;

    if (!isCodeStreamWaiting && !isCodeStreamInProgress && !isCodeStreamAvailable) {
        const testsStreamId = generateTestsStreamId(solveId, algorithmId);
        const complexityStreamId = generateComplexityStreamId(solveId, algorithmId);

        clientStore.streamsWaiting = [
            ...clientStore.streamsWaiting,
            codeStreamId,
            testsStreamId,
            complexityStreamId,
        ];

        void requestCodeOutputAction(solveId, algorithmId);
    }

    await router.push({
        name: ROUTES.solveOutput,
        params: {
            solveId: solveId,
            algorithmId: algorithmId,
        },
    });
};
