import { useSettingsStore } from '../../stores/SettingsStore.ts';
import { eventEmitterUtil } from '../../utils/EventEmitterUtil.ts';

export const hideAppAction = async () => {
    if (!window.electronApi) {
        return;
    }

    const settingsStore = useSettingsStore();
    if (!settingsStore.hideWarningShown) {
        eventEmitterUtil.emit('showFirstTimeHideWarning');
        settingsStore.hideWarningShown = true;

        return;
    }

    window.electronApi.hideApp();
};
