import { acceptHMRUpdate, defineStore } from 'pinia';

interface State {
    isLoggedIn: boolean;
}

export const useAuthStore = defineStore('auth', {
    state: (): State => {
        return {
            isLoggedIn: false,
        };
    },
    persist: {
        storage: sessionStorage,
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
}
