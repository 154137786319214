<script setup lang="ts">
import { useHostStore } from '../../stores/HostStore.ts';
import { onMounted, ref } from 'vue';
import { requestSourcesAction } from '../../actions/electron/RequestSourcesAction.ts';
import { InputSource } from '../../types/electron/InputSource.ts';
import Loader from '../Loader.vue';
import {
    MenubarCheckboxItem,
    MenubarItem,
    MenubarSeparator,
    MenubarSub,
    MenubarSubContent,
    MenubarSubTrigger,
} from '../ui/menubar';
import { setInputSourceAction } from '../../actions/solve/SetInputSourceAction.ts';
import { isSourceAutoDetected } from '../../utils/AutoDetectUtil.ts';

const hostStore = useHostStore();
const hasMissingScreenPermission = ref(false);
const screenPermissionFetched = ref(false);

const getSources = () => {
    requestSourcesAction();

    if (!screenPermissionFetched.value) {
        window.electronApi.requestPermissionStatus();
        screenPermissionFetched.value = true;
    }
};

const setSource = (source: InputSource) => {
    setInputSourceAction(source);
};

const openPermissionWindow = () => {
    window.electronApi.openPermissionWindow();
};

onMounted(async () => {
    window.electronApi.onRequestPermissionStatusResponse(async (status: { screen: boolean }) => {
        if (!status.screen) {
            hasMissingScreenPermission.value = true;
        }
    });

    if (!hostStore.sources.length) {
        getSources();
    }
});
</script>

<template>
    <MenubarSub>
        <MenubarSubTrigger>Input source</MenubarSubTrigger>
        <MenubarSubContent>
            <MenubarItem
                v-if="hasMissingScreenPermission"
                @click="openPermissionWindow"
                @select="(event: Event) => event.preventDefault()"
            >
                <div class="w-60 text-center text-red-700">
                    Leetcode Wizard needs permission to view your screen, click here to open your
                    permission settings.
                </div>
            </MenubarItem>
            <MenubarCheckboxItem
                v-for="source in hostStore.sources"
                v-else
                :key="source.id"
                :checked="source.id === hostStore.selectedSourceId"
                :disabled="hostStore.sourcesLoading"
                @click="setSource(source)"
                @select="(event: Event) => event.preventDefault()"
                :class="{ 'border border-green-500': isSourceAutoDetected(source.name) }"
            >
                <img :src="source.thumbnail" alt="Thumbnail" class="me-2 h-12 w-20 rounded" />
                <span class="max-w-48 truncate">
                    {{ source.name }}
                </span>
            </MenubarCheckboxItem>

            <MenubarSeparator />

            <MenubarItem
                class="flex items-center justify-center"
                :disabled="hostStore.sourcesLoading"
                @click="getSources"
                @select="(event: Event) => event.preventDefault()"
            >
                <span v-if="!hostStore.sourcesLoading"> Refresh </span>
                <Loader v-else :width="18" :height="18" />
            </MenubarItem>
        </MenubarSubContent>
    </MenubarSub>
</template>
