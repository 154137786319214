import { WebSocketStreamData } from '../../../types/ws/WebSocketStreamData.ts';
import { useClientStore } from '../../../stores/ClientStore.ts';
import { useHostStore } from '../../../stores/HostStore.ts';
import { makeAlgorithmsRequestAction } from '../../../actions/solve/SolveAction.ts';
import { createTextToast } from '../../../utils/ToastUtil.ts';
import { router, ROUTES } from '../../../router.ts';

export const SolveRequestExtractStreamEndHandler = async (
    messageData: WebSocketStreamData,
): Promise<void> => {
    const clientStore = useClientStore();

    if (!(messageData.streamId in clientStore.streams)) {
        throw new Error(`Stream with ID ${messageData.streamId} not found`);
    }

    let data: { problem: string; exampleCode: string };
    try {
        data = JSON.parse(clientStore.streams[messageData.streamId]);
    } catch {
        createTextToast(
            'Failed to find a coding problem in your input',
            'Make sure the selected input source is fully visible',
            'destructive',
            true,
        );

        void router.push({
            name: ROUTES.index,
        });

        return;
    }

    if (!data || !data.problem) {
        createTextToast(
            'Failed to find a coding problem in your input',
            'Make sure the selected input source is fully visible',
            'destructive',
            true,
        );

        void router.push({
            name: ROUTES.index,
        });

        return;
    }

    const hostStore = useHostStore();

    hostStore.activeProblemExtractData = data.problem;
    hostStore.activeExampleCodeExtractData = data.exampleCode;

    // @TODO: Temp
    const imageData = hostStore.activeImageData ?? '';

    void makeAlgorithmsRequestAction(
        imageData,
        data.problem,
        data.exampleCode,
        messageData.solveId,
    );
};
