import { useClientStore } from '../../../stores/ClientStore.ts';
import { WebSocketStreamData } from '../../../types/ws/WebSocketStreamData.ts';
import { requestTestsOutputAction } from '../../../actions/solve/RequestTestsOutputAction.ts';
import { requestComplexityOutputAction } from '../../../actions/solve/RequestComplexityOutputAction.ts';
import { generateCodeStreamId } from '../../id/GenerateCodeStreamId.ts';
import { retrieveUserInfoAction } from '../../../actions/user/RetrieveUserInfoAction.ts';

export const solveRequestCodeStreamEndHandler = (messageData: WebSocketStreamData): void => {
    const clientStore = useClientStore();

    const activeSolveId = clientStore.activeSolveId;
    const activeAlgorithmId = clientStore.activeAlgorithmId;

    if (!activeSolveId || !activeAlgorithmId) {
        return;
    }

    // Ignore data from old requests
    const activeCodeStreamId = generateCodeStreamId(activeSolveId, activeAlgorithmId);
    if (messageData.streamId !== activeCodeStreamId) {
        return;
    }

    void requestTestsOutputAction(activeSolveId, activeAlgorithmId);
    void requestComplexityOutputAction(activeSolveId, activeAlgorithmId);

    setTimeout(() => {
        void retrieveUserInfoAction();
    }, 1000); // wait 1 second for service to process usage log output

    return;
};
