// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Leetcode Wizard
 * Leetcode Wizard API Description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SolveExtractRequestDto
 */
export interface SolveExtractRequestDto {
    /**
     *
     * @type {string}
     * @memberof SolveExtractRequestDto
     */
    imageData: string;
    /**
     *
     * @type {string}
     * @memberof SolveExtractRequestDto
     */
    solveId: string;
    /**
     *
     * @type {string}
     * @memberof SolveExtractRequestDto
     */
    streamId: string;
    /**
     *
     * @type {string}
     * @memberof SolveExtractRequestDto
     */
    aiModel: SolveExtractRequestDtoAiModelEnum;
}

/**
 * @export
 */
export const SolveExtractRequestDtoAiModelEnum = {
    Gpt4o: 'gpt-4o',
    Claude35Sonnet: 'claude-35-sonnet',
    O1Mini: 'o1-mini',
} as const;
export type SolveExtractRequestDtoAiModelEnum =
    (typeof SolveExtractRequestDtoAiModelEnum)[keyof typeof SolveExtractRequestDtoAiModelEnum];

/**
 * Check if a given object implements the SolveExtractRequestDto interface.
 */
export function instanceOfSolveExtractRequestDto(value: object): value is SolveExtractRequestDto {
    if (!('imageData' in value) || value['imageData'] === undefined) return false;
    if (!('solveId' in value) || value['solveId'] === undefined) return false;
    if (!('streamId' in value) || value['streamId'] === undefined) return false;
    if (!('aiModel' in value) || value['aiModel'] === undefined) return false;
    return true;
}

export function SolveExtractRequestDtoFromJSON(json: any): SolveExtractRequestDto {
    return SolveExtractRequestDtoFromJSONTyped(json, false);
}

export function SolveExtractRequestDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): SolveExtractRequestDto {
    if (json == null) {
        return json;
    }
    return {
        imageData: json['imageData'],
        solveId: json['solveId'],
        streamId: json['streamId'],
        aiModel: json['aiModel'],
    };
}

export function SolveExtractRequestDtoToJSON(value?: SolveExtractRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        imageData: value['imageData'],
        solveId: value['solveId'],
        streamId: value['streamId'],
        aiModel: value['aiModel'],
    };
}
