// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Leetcode Wizard
 * Leetcode Wizard API Description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TokenDataResponseDto
 */
export interface TokenDataResponseDto {
    /**
     *
     * @type {number}
     * @memberof TokenDataResponseDto
     */
    accessTokenExpiresIn: number;
    /**
     *
     * @type {string}
     * @memberof TokenDataResponseDto
     */
    fingerprint: string;
    /**
     *
     * @type {string}
     * @memberof TokenDataResponseDto
     */
    fingerprintHash: string;
    /**
     *
     * @type {string}
     * @memberof TokenDataResponseDto
     */
    accessToken: string;
    /**
     *
     * @type {string}
     * @memberof TokenDataResponseDto
     */
    refreshToken: string;
}

/**
 * Check if a given object implements the TokenDataResponseDto interface.
 */
export function instanceOfTokenDataResponseDto(value: object): value is TokenDataResponseDto {
    if (!('accessTokenExpiresIn' in value) || value['accessTokenExpiresIn'] === undefined)
        return false;
    if (!('fingerprint' in value) || value['fingerprint'] === undefined) return false;
    if (!('fingerprintHash' in value) || value['fingerprintHash'] === undefined) return false;
    if (!('accessToken' in value) || value['accessToken'] === undefined) return false;
    if (!('refreshToken' in value) || value['refreshToken'] === undefined) return false;
    return true;
}

export function TokenDataResponseDtoFromJSON(json: any): TokenDataResponseDto {
    return TokenDataResponseDtoFromJSONTyped(json, false);
}

export function TokenDataResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): TokenDataResponseDto {
    if (json == null) {
        return json;
    }
    return {
        accessTokenExpiresIn: json['accessTokenExpiresIn'],
        fingerprint: json['fingerprint'],
        fingerprintHash: json['fingerprintHash'],
        accessToken: json['accessToken'],
        refreshToken: json['refreshToken'],
    };
}

export function TokenDataResponseDtoToJSON(value?: TokenDataResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        accessTokenExpiresIn: value['accessTokenExpiresIn'],
        fingerprint: value['fingerprint'],
        fingerprintHash: value['fingerprintHash'],
        accessToken: value['accessToken'],
        refreshToken: value['refreshToken'],
    };
}
