import { AuthenticationService } from '../../services/AuthenticationService.ts';
import { useUserInfoStore } from '../../stores/UserInfoStore.ts';
import { useSettingsStore } from '../../stores/SettingsStore.ts';
import { eventEmitterUtil } from '../../utils/EventEmitterUtil.ts';
import { wsAuthLogIn } from '../../ws/requests/WsAuthLogIn.ts';
import { SubscriptionTier } from '../../types/user/SubscriptionTier.ts';
import { apiService } from '../../services/ApiService.ts';
import { resetAction } from '../app/ResetAction.ts';
import { router, ROUTES } from '../../router.ts';
import { useAuthStore } from '../../stores/AuthStore.ts';
import { useUiStore } from '../../stores/UiStore.ts';
import { AI_MODEL } from '../../constants/AiModel.ts';

/**
 * @throws {Error} On API error response
 */
export const logInTransferTokenAction = async (token: string) => {
    const apiResponse = await apiService.getApi().authLoginTransferTokenV1({
        loginTransferTokenRequestDto: {
            token: token,
        },
    });

    await resetAction(false);

    const { refreshToken, accessTokenExpiresIn, fingerprintHash, accessToken } =
        apiResponse.tokenData;

    AuthenticationService.instance.storeTokenData(
        accessToken,
        refreshToken,
        fingerprintHash,
        accessTokenExpiresIn,
    );

    const authStore = useAuthStore();
    authStore.isLoggedIn = true;

    const { creditsRemaining, username: userUsername, subscriptionTier } = apiResponse.userData;

    const userInfoStore = useUserInfoStore();
    userInfoStore.username = userUsername;
    userInfoStore.creditsRemaining = creditsRemaining;
    userInfoStore.subscriptionTier = subscriptionTier as SubscriptionTier;

    const settingsStore = useSettingsStore();
    settingsStore.rememberedUsername = userUsername;

    if (userInfoStore.subscriptionTier === SubscriptionTier.Trial) {
        settingsStore.activeAiModel = AI_MODEL['gpt-4o'];
    }

    // End onboarding
    settingsStore.isOnboarding = false;

    const uiStore = useUiStore();
    wsAuthLogIn(accessToken, !uiStore.isInsideWebView);

    eventEmitterUtil.emit('login');

    // Redirect
    if (apiResponse.redirectUrl === null) {
        await router.push(ROUTES.authLogin);

        return;
    }

    if (apiResponse.redirectUrl.startsWith('http')) {
        window.location.href = apiResponse.redirectUrl;
    } else {
        await router.push(apiResponse.redirectUrl);
    }
};
