import { Hotkey } from '../types/Hotkey.ts';

const SHORTCUT_CONFIGURATION = {
    [Hotkey.Up]: 'CmdOrCtrl+Up',
    [Hotkey.Down]: 'CmdOrCtrl+Down',
    [Hotkey.Left]: 'CmdOrCtrl+Left',
    [Hotkey.Right]: 'CmdOrCtrl+Right',
    [Hotkey.Solve]: 'CmdOrCtrl+1',
    [Hotkey.Reset]: 'CmdOrCtrl+2',
    [Hotkey.Hide]: 'CmdOrCtrl+3',
    [Hotkey.Quit]: 'CmdOrCtrl+4',
};

export { SHORTCUT_CONFIGURATION };
