<script setup lang="ts">
import { ROUTES } from '../../router.ts';
import { onMounted } from 'vue';
import Loader from '../../components/Loader.vue';
import { logInTransferTokenAction } from '../../actions/auth/LogInTransferTokenAction.ts';
import { useRoute, useRouter } from 'vue-router';
import { createTextToast } from '../../utils/ToastUtil.ts';

const route = useRoute();
const router = useRouter();

onMounted(async () => {
    const token = route.query['t'];

    if (!token) {
        createTextToast('Missing transfer token', 'Please log in manually', 'destructive', false);

        await router.push(ROUTES.index);

        return;
    }

    try {
        await logInTransferTokenAction(token.toString());
    } catch (error) {
        console.error(error);

        createTextToast(
            'Failed to automatically log you in',
            'Please log in manually',
            'destructive',
            false,
        );

        await router.push(ROUTES.authLogin);

        return;
    }
});
</script>

<template>
    <div
        class="flex h-screen flex-col items-center pt-12 text-lg font-semibold dark:bg-gray-900 dark:text-white"
    >
        Logging you in...
        <Loader :width="32" :height="32" class="mt-4" />
    </div>
</template>
