import { WebSocketMessageData } from '../../../types/ws/WebSocketMessageData.ts';
import { useUiStore } from '../../../stores/UiStore.ts';
import { useSettingsStore } from '../../../stores/SettingsStore.ts';
import { SettingsStoreSyncData } from '../../../types/sync/SettingsStoreSyncData.ts';

export const syncSetSettingsStoreMessageHandler = (messageData: WebSocketMessageData) => {
    const uiStore = useUiStore();
    if (!uiStore.isInsideWebView) {
        return;
    }

    const storeData: SettingsStoreSyncData | undefined = messageData?.storeData;
    if (storeData === undefined) {
        return;
    }

    const settingsStore = useSettingsStore();
    settingsStore.$patch(storeData);
};
