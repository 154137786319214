import { WebSocketService } from '../../ws/WebSocketService.ts';
import { WS_REQUEST } from '../../ws/WebSocketEvents.ts';
import { STATIC_NAMESPACE_ID } from '../../constants/StaticNamespaceId.ts';
import { useUiStore } from '../../stores/UiStore.ts';
import { SettingsStoreSyncData } from '../../types/sync/SettingsStoreSyncData.ts';

export const syncSetSettingsStoreAction = (storeData: SettingsStoreSyncData): void => {
    const uiStore = useUiStore();
    if (uiStore.isInsideWebView) {
        throw new Error('syncSetSettingsStoreAction should not be called from inside the web view');
    }

    WebSocketService.instance.send(WS_REQUEST.sync_setSettingsStore, {
        storeData: storeData,
        id: STATIC_NAMESPACE_ID,
    });
};
