<script setup lang="ts">
import { Drawer, DrawerContent } from '../ui/drawer';
import { onMounted, onUnmounted, reactive } from 'vue';
import { eventEmitterUtil } from '../../utils/EventEmitterUtil.ts';
import { Progress } from '../ui/progress';
import { DialogHeader, DialogTitle } from '../ui/dialog';

interface ComponentState {
    imageData: string;
    isOpen: boolean;
    progressBarValue: number;
}

const state: ComponentState = reactive({
    imageData: '',
    isOpen: false,
    progressBarValue: 100,
});

const openDrawer = (imageData: string) => {
    state.imageData = imageData;
    state.isOpen = true;
    state.progressBarValue = 100;

    const progressBarInterval = setInterval(() => {
        state.progressBarValue -= 2.5;
        if (state.progressBarValue <= 0) {
            state.isOpen = false;
            clearInterval(progressBarInterval);
        }
    }, 2000 / 40); // 2 seconds (20ms interval)
};

onMounted(() => {
    eventEmitterUtil.addListener('showSolvePreview', openDrawer);
});

onUnmounted(() => {
    eventEmitterUtil.removeListener('showSolvePreview', openDrawer);
});
</script>

<template>
    <Drawer :open="state.isOpen">
        <DrawerContent class="outline-none">
            <DialogHeader>
                <DialogTitle :hidden="true">Processing your input:</DialogTitle>
            </DialogHeader>
            <div class="my-4 flex flex-col items-center justify-center">
                Processing your input:
                <img
                    :src="state.imageData"
                    alt="Input preview"
                    class="my-4 max-w-[340px] rounded"
                />
            </div>
            <Progress :model-value="state.progressBarValue" class="h rounded-none opacity-50" />
        </DrawerContent>
    </Drawer>
</template>
