import { WebSocketMessageData } from '../../../types/ws/WebSocketMessageData.ts';
import { createTextToast } from '../../../utils/ToastUtil.ts';
import { useUiStore } from '../../../stores/UiStore.ts';

export const syncPushToastMessageHandler = (messageData: WebSocketMessageData) => {
    const uiStore = useUiStore();
    if (!uiStore.isInsideWebView) {
        return;
    }

    if (!messageData.title) {
        return;
    }

    createTextToast(messageData?.title, messageData?.description, messageData?.variant, false);
};
